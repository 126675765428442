import { Link, useLocation } from "react-router-dom";
import './Breadcrumbs.scss'

const Breadcrumbs = ({nameCrumb}) => {

    const location = useLocation()
    
    let changedCrumb = ''

    function changeNameCrumb (crumbElement){
        switch (crumbElement) {
            case '/':
                changedCrumb = "Головна"
                break;
            case 'devision':
                changedCrumb = "Місця надання послуг"
                break;
            case 'doctors':
                changedCrumb = "Наші спеціалісти"
                break;
            case 'news':
                changedCrumb = "Новини"
                break;
            default:
                changedCrumb = nameCrumb
                break;
        }
    }

    let currentLink = ''

    const locationCrumbs = location?.pathname.split('/')
    const crumb = ['/',...locationCrumbs]
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `${crumb}`
            changeNameCrumb(crumb)

            return (
                <div className="crumb" key={crumb}>
                    <Link to={currentLink}>{changedCrumb}</Link>
                </div>
            )
        })
    crumb.pop()

    return (
        <div className="breadcrumbs">
            {crumb}
        </div>
    );
};

export default Breadcrumbs;