import React, { useEffect, useState } from 'react';
import './AdminHeader.scss'
import logo from '../../../img/logo-dark-2.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../../utils/helpers';

const AdminHeader = () => {

    const [signOut,setSignOut] = useState(false)

    const navigate = useNavigate()

    const signOutFunction = () => {
        deleteCookie('tk')
        setSignOut(true)
    }

    useEffect(() => {
        if (signOut){
            navigate('/login')
        }
    },[signOut,navigate])

    return (
        <header className='admin_header_wrapper'>
            <div className="admin_header_content">
                <div className='admin_header_logo'>
                    <Link to='/' className='admin_header_link' target='_blank'>
                        <img className='admin_header_logo_img' src={logo} alt='Logo'/>
                    </Link>
                </div>
                <div className="admin_logout_btn">
                    <Link className='main_admin_link' to='/admin-panel'>На головну</Link>
                    <button className='sign_out' onClick={() => signOutFunction()}>Вихід</button>
                </div>
            </div>
        </header>
    );
};

export default AdminHeader;