import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ChakraProvider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import { Input } from "../../../FormElement";
import { apiDevisions, apiSpecialties } from "../../../../axios";
import SelectMulti from "../../../FormElement/components/SelectMulti";

const CreateEditDoctors = ({
    visible,
    onCancel,
    obj = null,
    handleSuccess,
}) => {
    const [devision, setDevision] = useState([]);
    const [specialties, setSpecialties] = useState([]);

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false,
    });

    const onSubmit = (data) => {
        handleSuccess({
            ...data,
            devision: data?.devision?.map((itm) => itm?.value),
            specialty: data?.specialty?.map((itm) => itm?.value),
        });
        onCancel();
    };

    const getDataDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataSpecisalties = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getDataDevisions({});
        getDataSpecisalties({});
        reset({
            ...obj,
            devision: obj?.devision?.map((itm) => ({
                label: itm?.nameDevision,
                value: itm?._id,
            })),
            specialty: obj?.specialty?.map((itm) => ({
                label: itm?.specialty,
                value: itm?._id,
            })),
        });
        return () => {
            reset({});
        };
    }, [reset, obj, getDataDevisions, getDataSpecisalties]);

    const optionsDevisions = useMemo(
        () =>
            devision.map((item) => ({
                label: item?.nameDevision,
                value: item?._id,
            })),
        [devision]
    );

    const optionsSpecialties = useMemo(
        () =>
            specialties.map((item) => ({
                label: item?.specialty,
                value: item?._id,
            })),
        [specialties]
    );

    return (
        <ChakraProvider>
            <Modal isOpen={visible} size="lg" onClose={onCancel}>
                <ModalOverlay className="admin_modal_overlay" />
                <ModalContent>
                    <ModalHeader className="admin_modal_tit">Лікар</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form
                            className="admin_administration_modal"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Input
                                name={"firstName"}
                                control={control}
                                placeholder="Прізвище"
                            />
                            <Input
                                name={"secondName"}
                                control={control}
                                placeholder="Імʼя"
                            />
                            <Input
                                name={"surname"}
                                control={control}
                                placeholder="По батькові"
                            />
                            <Input
                                name={"specialtyCategory"}
                                control={control}
                                placeholder="Категорія"
                            />
                            <Input
                                name={"education"}
                                control={control}
                                placeholder="Освіта"
                            />
                            <Input
                                name={"treatmentLink"}
                                control={control}
                                placeholder="Посилання на запис / або текст"
                            />
                            <SelectMulti
                                name={"devision"}
                                control={control}
                                placeholder="Місце надання послуг"
                                data={optionsDevisions}
                            />
                            <SelectMulti
                                name={"specialty"}
                                control={control}
                                placeholder="Спеціальність"
                                data={optionsSpecialties}
                            />
                            <div className="admin_modal_btns">
                                <input
                                    className="admin_modal_save_btn"
                                    value={obj?.id ? "Оновити" : "Зберегти"}
                                    type="submit"
                                />
                                <button
                                    className="admin_modal_cancel_btn"
                                    type="button"
                                    onClick={onCancel}
                                >
                                    Скасувати
                                </button>
                            </div>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
};

export default CreateEditDoctors;
