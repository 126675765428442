import React from "react";
import { useController } from "react-hook-form";
import InputMask from "react-input-mask";

const TimeInput = ({ name, control, rules = {}, disabled = false, defaultValue = '', ...props }) => {
    const { field, fieldState: { error } } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled
    });

    return <>
        <InputMask
            mask="99:99-99:99"
            className='admin_modal_label'
            {...field}
            {...props}
        />
        {error?.message && <p className="form_error_txt">{error?.message}</p>}
    </>
};

export default TimeInput;