import React from 'react';
import './Loader.scss'

const Loader = ({fullscreen}) => {
    console.log();
    return (
        <div className={fullscreen ? "spinner spinner_full" : "spinner" }>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default Loader;