import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./AdminDocuments.scss";
import { apiDocuments } from "../../../axios";
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { alertForConfirmRequest } from "../../../utils/helpers";
import { useModal } from "../../../components/Modals";
import Table from "../../../components/Table";
import {
    adminDocumentsPost,
    adminDocumentsUpdate,
    adminDocumentsDelete,
} from "../../../axios/admin/manageDocuments";
import { PAGE_LIMIT_ADMIN } from "../../../data/globalConstants";

const AdminDocuments = () => {
    const dispatchModal = useModal();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);

    const getData = useCallback(
        async (paramsObj) => {
            try {
                setLoading(true);
                const result = await apiDocuments.getDocuments(paramsObj);
                const documentsCount =
                    paramsObj?.page === 1
                        ? await apiDocuments.getDocumentsCount(paramsObj)
                        : count;
                setCount(documentsCount);
                setData(result);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        },
        [count]
    );

    const createHandler = useCallback(
        async (fieldsObj = {}) => {
            await adminDocumentsPost(fieldsObj);
            getData({
                page: page,
                limit: PAGE_LIMIT_ADMIN,
                search: search,
            });
        },
        [getData, search, page]
    );

    const deleteHandler = useCallback(
        async (id) => {
            await adminDocumentsDelete({
                id: id,
            });
            getData({
                page: page,
                limit: PAGE_LIMIT_ADMIN,
                search: search,
            });
        },
        [getData, search, page]
    );

    const updateHandler = useCallback(
        async (fieldsObj) => {
            await adminDocumentsUpdate(fieldsObj);
            getData({
                page: page,
                limit: PAGE_LIMIT_ADMIN,
                search: search,
            });
        },
        [getData, search, page]
    );

    const createOrUpdate = useCallback(
        (obj = {}) => {
            dispatchModal("createEditDocuments", {
                obj: obj,
                handleSuccess: (result) =>
                    obj?.id ? updateHandler(result) : createHandler(result),
            });
        },
        [dispatchModal, createHandler, updateHandler]
    );

    useEffect(() => {
        getData({
            page: page,
            limit: PAGE_LIMIT_ADMIN,
            search: search,
        });
    }, [getData, search, page]);

    const columns = useMemo(
        () => [
            {
                key: "name",
                children: "Назва",
            },
            {
                key: "link",
                children: "Посилання",
            },
            {
                key: "controls",
                style: {
                    width: 145,
                },
                children: "Керування",
            },
        ],
        []
    );

    const dataRows = useMemo(
        () =>
            data.map((itm) => ({
                key: itm?._id,
                className: "admin_table_row",
                rowData: [
                    {
                        children: itm?.name,
                    },
                    {
                        children:
                            itm?.link?.length > 65
                                ? itm?.link?.substring(0, 65) + "..."
                                : itm?.link,
                    },
                    {
                        className: "admin_controll_btn",
                        children: (
                            <>
                                <button
                                    className="admin_rewrite_btn"
                                    onClick={() =>
                                        createOrUpdate({
                                            id: itm?._id,
                                            name: itm?.name,
                                            link: itm?.link,
                                        })
                                    }
                                >
                                    <FaPen />
                                </button>
                                <button
                                    className="admin_delete_btn"
                                    onClick={() =>
                                        alertForConfirmRequest(
                                            deleteHandler,
                                            itm?._id
                                        )
                                    }
                                >
                                    <MdDelete />
                                </button>
                            </>
                        ),
                    },
                ],
            })),
        [data, createOrUpdate, deleteHandler]
    );

    const emptyText = useMemo(() => {
        return search === "" ? (
            <div className="admin_empty_table">
                Список документів порожній
                <br />
                <button
                    className="admin_create_element"
                    onClick={() => createOrUpdate()}
                >
                    Створити
                </button>
            </div>
        ) : (
            `За пошуком "${search}" нічого не знайдено`
        );
    }, [search, createOrUpdate]);

    return (
        <section className="admin_administration_wrapper">
            <div className="admin_page_content">
                <h2 className="admin_page_title">Документи</h2>
                <div className="admin_filters_block">
                    <input
                        className="admin_search_input"
                        type="text"
                        placeholder="Пошук"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                        className="admin_create_element"
                        onClick={() => createOrUpdate()}
                    >
                        Створити
                    </button>
                </div>

                <div className="admin_table_block">
                    <Table
                        columns={columns}
                        data={dataRows}
                        loading={loading}
                        emptyText={emptyText}
                        pagination={{
                            count: count,
                            page: page,
                            onChange: (number) => setPage(number),
                            pageLimit: PAGE_LIMIT_ADMIN,
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default AdminDocuments;
