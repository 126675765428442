import React, { useCallback, useEffect, useState } from 'react';
import './News.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import { apiNews } from '../../axios';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { getPagination } from '../../utils/helpers';

const News = () => {

    const [news, setNews] = useState([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);

    const getDataNews = useCallback(async (paramsObj) => {
        try {
            const result = await apiNews.getNews(paramsObj);
            const count = paramsObj?.page === 1 ? await apiNews.getNewsCount(paramsObj) : 0;
            setNews(result);
            setPagination(prev => paramsObj?.page === 1 ? getPagination(count, 20) : prev);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getDataNews({
            page: page,
            limit: 20,
            search: search
        })
    }, [search, page, getDataNews])

    return (
        <section className='news_wrapper'>
            <div className='news_content'>

                {/* Head block */}
                <HeadBlock text='Новини' />

                {/* News list */}
                <input className='news_search_input' onChange={e => setSearch(e.target.value)} placeholder='Пошук новини' />
                {news.length !== 0 ? <>
                    <div className='news_list_box'>
                        {news.toReversed().map(elem => {
                            return <div className='news_block' key={elem?._id}>
                                <Link className='news_block_box' to={'/news/' + elem._id}>
                                    <div className='news_block_img_wrapper'>
                                        <img className='news_block_img' src={elem.image} alt={elem.title} />
                                    </div>
                                    <div className='news_block_info_wrapper'>
                                        <p className='news_block_title'>{elem.title}</p>
                                        <div className='news_block_short_descr' dangerouslySetInnerHTML={{ __html: elem.description.length >= 24 ? elem.description.substring(0, 65) + '...' : elem.description }} />
                                        <p className='news_block_date'>{elem.date}</p>
                                    </div>
                                </Link>
                            </div>
                        })}
                        <div className='news_pagination'>
                            {pagination.length > 1 && pagination.map((elem, index) => {
                                return <p className={page === elem ? 'actived_pagination' : ''} key={index} onClick={() => setPage(elem)}>{elem}</p>
                            })}
                        </div>
                    </div>
                </> : <Loader />}

                {/* News slider */}
                <div className='news_page_slider'>
                    <h2 className='news_page_block_tit'>Останні новини</h2>
                    <NewsSlider start={0} end={10} />
                </div>
            </div>
        </section>
    );
};

export default News;