import React, { useCallback, useEffect, useState } from 'react';
import './NewInfo.scss'
import { useNavigate, useParams } from 'react-router-dom';
import { apiNews } from '../../axios';
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';

const NewInfo = () => {

    const { id } = useParams()
    const [news, setNews] = useState(null)
    const [errorRequest, setErrorRequest] = useState(null)
    const navigate = useNavigate()

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiNews.getNew(paramsObj);
            setNews(result);
        } catch (error) {
            console.log(error);
            error && setErrorRequest(error)
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData(id);
        errorRequest && navigate("/error")
    }, [id,getData,errorRequest,navigate])

    return (
        <section className='new_info_wrapper'>
            <div className='new_info_content'>
                {news === null ? <Loader fullscreen/> :
                    <>
                        {/* Head block */}
                        <HeadBlock text={news?.title} />

                        <section className='new_info_box'>
                            <h2 className='new_info_tit_block'>Про новину</h2>
                                <div className='new_info_box_content'>
                                    <div className='new_info_box_img'>
                                        <img className='new_info_img' src={news?.image} alt={news?.title} />
                                    </div>
                                    <div className='new_info_box_desc'>
                                        <p className='new_info_date'>{news?.date}</p>
                                        <div className='new_info_desc' dangerouslySetInnerHTML={{ __html: news.description}} />
                                    </div>
                                </div>
                            <Button text='Назад до новин' path={"news"} />
                        </section>

                        {/* News slider */}
                        <section className='new_info_slider'>
                            <h2 className='new_info_tit_block'>Останні новини</h2>
                            <NewsSlider start={0} end={10} />
                        </section>
                    </>
                }

            </div>
        </section>
    );
};

export default NewInfo;