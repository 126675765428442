import { Navigate } from "react-router-dom";
import { getCookie } from "../utils/helpers";

const ProtectedRoute = ({component}) => {
    const signedPerson = getCookie('tk')

    if (signedPerson) { 
        return component
    } else {
        return <Navigate to='/login'/>
    }
};

export default ProtectedRoute;