import React, { useEffect } from 'react';
import './Error.scss'
import Button from '../../components/Button/Button';

const Error = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <div className='error_wrapper'>
            <div className="error_content">
                <h2> 404 </h2>
                <p> Дана сторінка не знайдена</p>
                <Button path={''} text={"Повернутись на головну"}/>
            </div>
        </div>
    );
};

export default Error;