import * as yup from "yup";
import { declension } from "../../../../utils/helpers";

const REQUIRED_MESSAGE = "Поле обовʼязкове для заповнення";
// const LINK_RULES = 'Посилання на зображення повинно починатись на http:// або https://'
const MIN_MESSAGE = (number = 1) =>
    `Мінімум ${number} ${declension(number, "символ", "символа", "символів")}`;

const schema = yup.object().shape({
    firstName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    secondName: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    surname: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    specialtyCategory: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    devision: yup
        .array()
        .required(REQUIRED_MESSAGE)
        .min(1, "Оберіть хочаб 1 заклад"),
    education: yup
        .string()
        .trim()
        .required(REQUIRED_MESSAGE)
        .min(3, MIN_MESSAGE(3)),
    specialty: yup
        .array()
        .required(REQUIRED_MESSAGE)
        .min(1, "Оберіть хочаб 1 спеціальність"),
});

export default schema;
