import React from 'react';
import './DevisionMap.scss'
import mapStyle from './MapStyles'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const DevisionMap = ({lat,lng}) => {
        
    const libraries = ['places'];

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDtQS8eNfACMU7ruTAK71LI7cby3gVrnPA',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }
    
    if (!isLoaded) {
        return <div>Loading maps</div>;
    }
    const center = {
        lat: +lat, // default latitude
        lng: +lng, // default longitude
    };

    const mapContainerStyle = {
        width: '60vw',
        height: '50vh',
        margin: '0 auto'
    };
    
    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={17}
                center={center}
                options={{ styles: mapStyle }}
            >
                <Marker position={center} />
            </GoogleMap>
        </div>
    );
};

export default DevisionMap;