import React, { useEffect, useState, useCallback, memo } from "react";
import "./Doctors.scss";
import { apiDoctors } from "../../axios";
import Button from "../Button/Button";
import ButtonLink from "../ButtonLink/ButtonLink";
import Loader from "../Loader/Loader";
import { getPagination } from "../../utils/helpers";

const Doctors = ({ devisionId }) => {
    const [doctors, setDoctors] = useState([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [pagination, setPagination] = useState([]);

    const regLinkOfDoctor = /^(https?|ftp):\/\//;

    const getDataDoctors = useCallback(
        async (paramsObj) => {
            try {
                const result = await apiDoctors.getDoctors(paramsObj);
                const count =
                    paramsObj?.page === 1
                        ? await apiDoctors.getDoctorsCount(paramsObj)
                        : 0;
                setDoctors(result);
                setPagination((prev) =>
                    paramsObj?.page === 1 ? getPagination(count, limit) : prev
                );
            } catch (error) {
                console.log(error);
            }
        },
        [limit]
    );

    useEffect(() => {
        // if (!devisionName || devisionName === null || devisionName === '') return;
        getDataDoctors({
            page: page,
            limit: limit,
            filters: {
                devision: devisionId,
            },
            fio: search,
        });
    }, [devisionId, getDataDoctors, search, limit, page]);

    return (
        <div className="doctors_wrapper">
            <div className="doctors_filters">
                <input
                    type="text"
                    className="doctors_search"
                    placeholder="Пошук за ПІП або спеціальністю"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <label className="doctors_limit_label" htmlFor="limit_select">
                    Кількість
                </label>
                <select
                    className="doctors_limit"
                    onChange={(e) => setLimit(e.target.value)}
                    id="limit_select"
                >
                    <option value={20}>20</option>
                    <option value={40}>40</option>
                    <option value={40}>60</option>
                    <option value={200}>Всі</option>
                </select>
            </div>
            <div className="doctors_content">
                {doctors.length !== 0 ? (
                    <>
                        {doctors.map((elem) => {
                            return (
                                <div className="doctor_box" key={elem._id}>
                                    <p className="doctor_name">
                                        {elem.firstName} {elem.secondName}{" "}
                                        {elem.surname}
                                    </p>
                                    <hr noshade="true" className="doctor_hr" />
                                    <p className="doctor_specialty">
                                        {elem.specialty
                                            ?.map((itm) => itm?.specialty)
                                            .join(", ")}
                                    </p>
                                    <div className="doctor_btn">
                                        {elem?.treatmentLink?.lenght}
                                        <Button
                                            text={"Детальніше"}
                                            path={`doctors/${elem._id}`}
                                        />
                                        {elem?.treatmentLink?.match(
                                            regLinkOfDoctor
                                        ) ? (
                                            <ButtonLink
                                                text="Записатись"
                                                path={elem?.treatmentLink}
                                            />
                                        ) : (
                                            false
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <Loader />
                )}
            </div>
            <div className="doctors_pagination">
                {pagination.length > 1 &&
                    pagination.map((elem, index) => {
                        return (
                            <p
                                className={
                                    page === elem ? "actived_pagination" : ""
                                }
                                key={index}
                                onClick={() => setPage(elem)}
                            >
                                {elem}
                            </p>
                        );
                    })}
            </div>
        </div>
    );
};

export default memo(Doctors);
