import React, { useEffect } from 'react';
import './Treatment.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import Schedole from '../../components/Schedole/Schedule';

const Treatment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <section className='treatment_wrapper'>
            <div className='treatment_content'>
                
                {/* Head block */}
                <HeadBlock text='Лікуватись'/>

                <section className='treatment_schedule_block'>
                    <h2 className='tretment_title_text'>Оберіть місце надання послуг</h2>
                    <Schedole/>
                </section>
            </div>
        </section>
    );
};

export default Treatment;