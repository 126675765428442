import React from 'react';
import './Button.scss'
import { Link } from 'react-router-dom';

const Button = ({text, path}) => {
    return (
        <Link className='link_button' to={`/${path}`}>
            {text}
        </Link>
    );
};

export default Button;