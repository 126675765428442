import React, { useCallback, useEffect, useState } from 'react';
import './DevisionInfo.scss'
import Loader from '../Loader/Loader';
import ogienko from '../../img/main-head-bg.jpeg'
import franko from '../../img/franko_st.jpeg'
import stepBander from '../../img/s-banderu.jpeg'

import { apiSpecialties } from '../../axios';

const DevisionInfo = ({ devision = null }) => {
    const [specialties, setSpecialties] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({
            filters: {"devision" : devision?._id}
        })
    }, [devision?._id, getData]);

    return (
        <>
            {devision?.nameDevision ?
                <div className='devision_info_wrapper'>
                    <div className='devision_info_content'>
                        <div className='devision_info'>
                            <img src={
                                devision?.location?.adress?.includes('Степана') ?
                                    stepBander :
                                    devision?.location?.adress?.includes('Огієнка') ?
                                    ogienko :
                                    franko
                            }
                                className='devision_img'
                                alt={devision?.nameDevision}
                            />
                        </div>
                        <div className='devision_schedule'>
                            <p>Адреса: <span>{devision?.location?.adress}</span></p>
                            <p>ПН-ПТ: <span>{devision?.schedule?.weekdays}</span></p>
                            <p>СБ: <span>{devision?.schedule?.saturday}</span></p>
                            <p>НД: <span>{devision?.schedule?.sunday}</span></p>
                            {devision?._id === "654be174cd6323e6f4c93845" && <p>Примітка: <span>У суботу прийом проводиться черговим лікарем в порядку живої черги за умови наявності електронного скерування від лікаря</span></p>}
                            <br />
                            <p>Телефони:</p>
                            <a className='devision_contact' href={`tel:${devision?.phone?.mobile}`}>Мобільний: {devision?.phone.mobile}</a>
                            <a className='devision_contact' href={`tel:${devision?.phone?.stac}`}>Стаціонарний: {devision?.phone.stac}</a>
                        </div>
                    </div>
                    <div className='devision_specialties'>
                        <p className='devision_specialties_tit'>Спеціалісти: </p>
                        <ul className='devision_specialties_list'>
                            {specialties.map((specialty) => <li key={specialty?._id}>{specialty?.specialty}</li>)}
                        </ul>
                    </div>
                </div>
                :
                <Loader />
            }
        </>
    );
};

export default DevisionInfo;