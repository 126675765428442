import React, { useCallback, useEffect, useState } from 'react';
import './InformationAccordion.scss'
import { ChakraProvider } from '@chakra-ui/react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { apiAnalyses } from '../../axios';
import { apiDevisions } from '../../axios';
import { apiFaq } from '../../axios';

const InformationAccordion = () => {

    const [questions,setQuestions] = useState([])
    const [devisions,setDevisions] = useState([])
    const [analyses,setAnalyses] = useState([])

    const getDataFaq = useCallback(async (paramsObj) => {
        try {
            const result = await apiFaq.getFaques(paramsObj);
            setQuestions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataAnalyses = useCallback(async (paramsObj) => {
        try {
            const result = await apiAnalyses.getAnalyses(paramsObj);
            setAnalyses(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getDataFaq()
        getDataDevisions()
        getDataAnalyses()
    },[getDataFaq,getDataDevisions,getDataAnalyses])

    return (
        <div className='information_accord_wrapper'>
            <div className='information_accord_content'>
                <ChakraProvider>
                    <Accordion allowToggle>
                        <AccordionItem>
                            <AccordionButton className='information_accord_btn'>
                                <div>Відповіді на часті запитання</div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Accordion allowToggle className='information_questions_accord_box'>
                                {questions.map((element,index) => {
                                    return <AccordionItem key={index}>
                                                <AccordionButton className='information_question_btn'>
                                                    <div>{element.question}</div>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <p className='information_question_answer'>{element.answer}</p>
                                                </AccordionPanel>
                                            </AccordionItem>
                                })}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton className='information_accord_btn'>
                                <div>Реєстратура</div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                {devisions.map((devision,index) => {
                                    return <div className='information_registery_box' key={index}>
                                                <div className='information_registery_contacts'>
                                                    <p className='information_registery_name'>{devision.nameDevision}</p>
                                                    <p className='information_registery_contacts_text'>Контактні номера:</p>
                                                    <div className='information_registery_contacts_box'>
                                                            <a href={`tel:${devision.phone.mobile}`} className='information_registery_contacts_phone' >Мобільний: {devision.phone.mobile}</a>
                                                            <a href={`tel:${devision.phone.stac}`} className='information_registery_contacts_phone' >Стаціонарний: {devision.phone.stac}</a>
                                                    </div>
                                                </div>
                                                <div className='information_registery_info'>
                                                    <p className='information_registery_weekdays_text'>Графік роботи:</p>
                                                    <div className='information_registery_weekdays_box'>
                                                        <p className='information_registery_contacts_days'>ПН-ПТ: <span>{devision.schedule.weekdays}</span></p>
                                                        <p className='information_registery_contacts_days'>СБ: <span>{devision.schedule.saturday}</span></p>
                                                        <p className='information_registery_contacts_days'>НД: <span>{devision.schedule.sunday}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                })}
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionButton className='information_accord_btn'>
                                <div>Підготовка до здачі аналізів</div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Accordion allowToggle className='information_analyses_accord_box'>
                                    {analyses.map((analysis,index) => {
                                        return <AccordionItem key={index}>
                                                    <AccordionButton className='information_analyses_btn'>
                                                        <div>{analysis.analysisName}</div>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                    <AccordionPanel pb={4}>
                                                        <p className='information_analyses_descr'>{analysis.descr}</p>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                    })}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </ChakraProvider>
            </div>
        </div>
    );
};

export default InformationAccordion;