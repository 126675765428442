import React, { useCallback, useEffect, useState } from 'react';
import './InternshipAccordion.scss'
import { ChakraProvider } from '@chakra-ui/react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Table,
    Thead,
    Tbody,
    Tr,
    Th
} from '@chakra-ui/react'
import { apiInternships } from '../../axios';

const InternshipAccordion = () => {

    const [internship,setInternship] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiInternships.getInternships(paramsObj);
            setInternship(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData()
    },[getData])

    return (
        <div className='intership_accordion_wrapper'>
            <div className='intership_accordion_content'>
                <ChakraProvider>
                    {internship.length !== 0 ? 
                        <Accordion allowToggle>
                        <AccordionItem>
                            <AccordionButton className="intership_accord_btn">
                                <div>
                                    Спеціальності які доступні для інтернатури
                                </div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Table>
                                    <Thead className='table_internship_head'>
                                        <Tr>
                                            <Th>№</Th>
                                            <Th>Спеціальність</Th>
                                        </Tr>
                                    </Thead>
                                    {internship.map((elem,index) => {
                                        return <Tbody key={index}>
                                                    <Tr className='table_internship_row'>
                                                        <Th>{index + 1}</Th>
                                                        <Th>{elem.nameSpecialty}</Th>
                                                    </Tr>
                                                </Tbody>
                                    })}
                                </Table>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton className="intership_accord_btn">
                                <div>
                                    Тривалість інтернатури
                                </div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Table>
                                    <Thead className='table_internship_head'>
                                        <Tr>
                                            <Th>№</Th>
                                            <Th>Спеціальність</Th>
                                            <Th>Термін (років)</Th>
                                        </Tr>
                                    </Thead>
                                    {internship.map((elem,index) => {
                                        return <Tbody key={index}>
                                                    <Tr className='table_internship_row'>
                                                        <Th>{index + 1}</Th>
                                                        <Th>{elem.nameSpecialty}</Th>
                                                        <Th>{elem.years}</Th>
                                                    </Tr>
                                                </Tbody>
                                    })}
                                </Table>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionButton className="intership_accord_btn">
                                <div>
                                    Оплата за інтернатуру
                                </div>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <p className='intership_accord_text'>Вартість надання освітніх послуг лікарям - інтернам контрактної форми, які вступають на навчання за спеціальностями: </p>
                                <Table>
                                    <Thead className='table_internship_head'>
                                        <Tr>
                                            <Th>№</Th>
                                            <Th>Спеціальність</Th>
                                            <Th>Термін (років)</Th>
                                            <Th>Вартість на рік</Th>
                                            <Th>Всього (грн)</Th>
                                        </Tr>
                                    </Thead>
                                    {internship.map((elem,index) => {
                                        return <Tbody key={index}>
                                                    <Tr className='table_internship_row'>
                                                        <Th>{index + 1}</Th>
                                                        <Th>{elem.nameSpecialty}</Th>
                                                        <Th>{elem.years}</Th>
                                                        <Th>{elem.yearPrice}</Th>
                                                        <Th>{elem.yearPrice * elem.years}</Th>
                                                    </Tr>
                                                </Tbody>
                                    })}
                                </Table>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion> : 
                    <p className='empty_intership'>Вступ до інтернатури відсутній</p>
                }
                </ChakraProvider>
            </div>
        </div>
    );
};

export default InternshipAccordion;