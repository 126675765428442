import * as yup from "yup";
import { declension } from "../../../../utils/helpers";

const REQUIRED_MESSAGE = 'Поле обовʼязкове для заповнення';
const TIME_MESSAGE = 'Невірний формат часу';
const COORD_MESSAGE = 'Невірний формат координат';
const PHONE_MESSAGE = 'Заповніть номер коректно';
const COORD_REGULAR = /^\d{2}.\d{5}$/;
const TIME_REGULAR = /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/;
const PHONE_REGULAR = /^\+38\(\d{3}\)-\d{3}-\d{2}-\d{2}$/;
const MIN_MESSAGE = (number = 1) => `Мінімум ${number} ${declension(number, 'символ', 'символа', 'символів')}`;

const schema = yup.object().shape({
    nameDevision: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE(3)),
    location: yup.object().shape({
        lt: yup.string().trim().required(REQUIRED_MESSAGE).matches(COORD_REGULAR, COORD_MESSAGE),
        ln: yup.string().trim().required(REQUIRED_MESSAGE).matches(COORD_REGULAR, COORD_MESSAGE),
        adress: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE(3))
    }),
    schedule: yup.object().shape({
        weekdays: yup.string().trim().required(REQUIRED_MESSAGE).matches(TIME_REGULAR, TIME_MESSAGE),
        saturday: yup.string().trim(),
        sunday: yup.string().trim()
    }),
    phone: yup.object().shape({
        mobile: yup.string().trim().required(REQUIRED_MESSAGE).matches(PHONE_REGULAR, PHONE_MESSAGE),
        stac: yup.string().trim().required(REQUIRED_MESSAGE).matches(PHONE_REGULAR, PHONE_MESSAGE)
    })
});

export default schema;