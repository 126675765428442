import React, { useEffect,useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, RichUtils, getDefaultKeyBinding, ContentState, convertFromHTML } from "draft-js";
import 'draft-js/dist/Draft.css';
import './TextRedactor.scss'
import { stateToHTML } from 'draft-js-export-html';


const TextRedactor = ({redactorText, description}) => {
    console.log(description);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    )

    const onHandleKeyBindings = (e) => {
        if (e.keyCode === 9) {
            setEditorState(RichUtils.onTab(e, editorState, 4));
        } else {
            return getDefaultKeyBinding(e);
        }
    };

    useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(description || ''))));
    }, [description]);

    useEffect(() => {
        redactorText(stateToHTML(editorState.getCurrentContent()))
    },[editorState,redactorText])

    return (
        <div className='redactor_wrapper'>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarclassName="
                wrapperClassName="wrapperclassName="
                editorClassName="editorclassName="
                onEditorStateChange={setEditorState}
                onTab={onHandleKeyBindings}
            />
        </div>
    );
};

export default TextRedactor;