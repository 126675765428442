import React, { useCallback, useEffect, useState } from 'react';
import './Partners.scss'
import Loader from '../Loader/Loader';
import { apiPartners } from '../../axios';

const Partners = () => {

    const [partners,setPartners] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiPartners.getPartners(paramsObj);
            setPartners(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData()
    },[getData])

    return (
        <div className='partners_wrapper'>
            <div className='partners_content'>
                {partners.length !== 0 ? 
                    partners.map((partner,index) => {
                        return <a className='parner_link' href={partner.link} target='_blank' rel='noreferrer' key={index}>
                                    <img className='partner_img' src={partner.image} alt={partner.partnerName} key={index}/>
                                </a>
                    })
                :
                    <Loader/>
                }
            </div>
        </div>
    );
};

export default Partners;