import React, { useEffect } from 'react';
import './DoctorsAll.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import Doctors from '../../components/Doctors/Doctors';

const DoctorsAll = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })
    
    return (
        <section className='doctors_all_wrapper'>
            <div className='doctors_all_content'>

                {/* Head block */}
                <HeadBlock text={"Наші спеціалісти"}/>
                
                <div className='doctors_block'>
                    <Doctors/>
                </div>
            </div>
        </section>
    );
};

export default DoctorsAll;