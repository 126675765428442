import React from 'react';
import {Outlet} from 'react-router-dom'
import AdminHeader from '../AdminPanel/Admin-header/AdminHeader';
import AdminAside from '../../pages/Admin/AdminAside/AdminAside';

const AdminLayout = () => {
    return (
        <div>
            <AdminHeader/>
            <AdminAside/>
            <Outlet/>
        </div>
    );
};

export default AdminLayout;