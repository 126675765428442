import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import './NewsSlider.scss'
import 'swiper/css';
import 'swiper/css/pagination';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';
import newErrImg from '../../img/404_news.jpeg'

import { apiNews } from '../../axios';

const NewsSlider = ({start,end}) => {

    const [news,setNews] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiNews.getNews(paramsObj);
            setNews(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData()
    },[getData])

    return (
        <>
            {news.length !== 0 ? 
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                    }}
                    autoplay={{
                        delay: 2750,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination,Autoplay]}
                    className="newsSlider"
                >
                    {news?.toReversed().slice(start,end).map((element) => {
                            return <SwiperSlide key={element?._id}>
                                            <div className='news_block_slide'>
                                                <Link className='news_block_slide_box' to={'/news/'+element._id}>
                                                    <div className='news_block_img_wrapper'>
                                                        <img className='news_block_img_slide' src={element?.image || newErrImg} alt={element?.title} />
                                                    </div>
                                                    <div className='news_block_info_wrapper'>
                                                        <p className='news_block_date_slide'>{element?.date}</p>
                                                        <p className='news_block_title_slider'>{element?.title}</p>
                                                        <div className='news_block_short_descr_slide' dangerouslySetInnerHTML={{ __html: element?.description.length >= 24 ? element?.description.substring(0, 65) + '...' : element?.description }}/>
                                                    </div>
                                                </Link>
                                            </div>
                                    </SwiperSlide>
                        })
                    }
                </Swiper>
                :
                <Loader/>
            }
        </>
    );
};

export default NewsSlider;