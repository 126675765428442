import React from 'react';
import './HeadBlock.scss'
import Breadcrumb from '../Breadcrumbs/Breadcrumbs';

const HeadBlock = ({text}) => {
    return (
        <div className='head_block_wrapper'>
            <div className='head_block_content'>
                <h1 className='head_block_txt'>{text}</h1>
            </div>
            <Breadcrumb nameCrumb={text}/>
        </div>
    );
};

export default HeadBlock;