import { ModalStore, useModal } from "react-modal-store";

import CreateEditAdministration from "./components/CreateEditAdministration";
import CreateEditNews from "./components/CreateEditNews";
import CreateEditAnalyses from "./components/CreateEditAnalyses";
import CreateEditDevisions from "./components/CreateEditDevisions";
import CreateEditSpecialties from "./components/CreateEditSpecialties";
import CreateEditDoctors from "./components/CreateEditDoctors";
import CreateEditFaq from "./components/CreateEditFaq";
import CreateEditInternship from "./components/CreateEditInternShips";
import CreateEditPartners from "./components/CreateEditPartners";
import CreateEditPatrons from "./components/CreateEditPatrons";
import CreateEditServices from "./components/CreateEditServices";
import CreateEditVacancies from "./components/CreateEditVacancies";
import CreateEditDocuments from "./components/CreateEditDocuments";

const modalMap = {
    createEditAdministration: CreateEditAdministration,
    createEditNews: CreateEditNews,
    createEditAnalyses: CreateEditAnalyses,
    createEditDevisions: CreateEditDevisions,
    createEditSpecialties: CreateEditSpecialties,
    createEditDoctors: CreateEditDoctors,
    createEditFaq: CreateEditFaq,
    createEditInternship: CreateEditInternship,
    createEditPartners: CreateEditPartners,
    createEditPatrons: CreateEditPatrons,
    createEditServices: CreateEditServices,
    createEditVacancies: CreateEditVacancies,
    createEditDocuments: CreateEditDocuments,
    //тут додаємо усі модалки
};

export { modalMap, ModalStore, useModal };
