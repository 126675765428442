import React, { useEffect, useState } from "react";
import {
    ChakraProvider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import schema from "./schema";
import { Input } from "../../../FormElement";
import TextRedactor from "../../../TextRedactor/TextRedactor";

const CreateEditNews = ({ visible, onCancel, obj = null, handleSuccess }) => {

    const [redactorText,setRedactorText] = useState(null)
    
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false
    })

    const onSubmit = data => {
        handleSuccess({...data, description:redactorText});
        onCancel();
    };

    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    return <ChakraProvider>
        <Modal isOpen={visible} size='lg' onClose={onCancel}>
            <ModalOverlay className='admin_modal_overlay' />
            <ModalContent>
                <ModalHeader className='admin_modal_tit'>Новина</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form className='admin_administration_modal'
                        onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            name={"title"}
                            control={control}
                            placeholder='Заголовок'
                        />
                        <Input
                            name={"image"}
                            control={control}
                            placeholder='Посилання на зображення'
                        />
                        <TextRedactor redactorText={setRedactorText} description={obj?.description}/>
                        <div className='admin_modal_btns'>
                            <input className='admin_modal_save_btn' value={obj?.id ? 'Оновити' : 'Зберегти'} type="submit" />
                            <button className='admin_modal_cancel_btn' type='button' onClick={onCancel}>
                                Скасувати
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    </ChakraProvider>;
};

export default CreateEditNews;