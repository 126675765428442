import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Doctor.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiDevisions, apiDoctors } from "../../axios";
import HeadBlock from "../../components/HeadBlock/HeadBlock";
import Loader from "../../components/Loader/Loader";
import doctorImage from "../../img/doctor.svg";
import ButtonLink from "../../components/ButtonLink/ButtonLink";
import DoctorsSlider from "../../components/DoctorsSlider/DoctorsSlider";

const Doctor = () => {
    const { id } = useParams();
    const [doctor, setDoctor] = useState(null);
    const [devision, setDevision] = useState([]);
    const [errorRequest, setErrorRequest] = useState(null);
    const navigate = useNavigate();

    const getDataDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataDoctor = useCallback(async (paramsObj) => {
        try {
            const result = await apiDoctors.getDoctor(paramsObj);
            setDoctor(result);
        } catch (error) {
            error && setErrorRequest(error);
            console.log(error);
        }
    }, []);

    const regLinkOfDoctor = /^(https?|ftp):\/\//;

    useEffect(() => {
        window.scrollTo(0, 0);
        getDataDoctor(id);
        getDataDevisions();
        errorRequest && navigate("/error");
    }, [id, getDataDoctor, getDataDevisions, errorRequest, navigate]);

    const viewDevision = useMemo(() => {
        return devision.filter((elem) =>
            doctor?.devision?.map((itm) => itm?._id).includes(elem?._id)
        );
    }, [doctor?.devision, devision]);

    return (
        <section className="doctor_wrapper">
            <div className="doctor_content">
                {doctor === null ? (
                    <Loader fullscreen />
                ) : (
                    <>
                        {/* Head block */}
                        <HeadBlock
                            text={`${doctor?.firstName} ${doctor?.secondName} ${doctor?.surname}`}
                        />

                        {/* Doctor info block */}
                        <div className="doctor_info">
                            <div className="doctor_info_img_box">
                                <img
                                    className="doctor_info_image"
                                    src={doctorImage}
                                    alt={doctor?.firstName}
                                />
                            </div>
                            <div className="doctor_info_text">
                                <p className="doctor_info_name">
                                    {doctor?.firstName} {doctor?.secondName}{" "}
                                    {doctor?.surname}
                                </p>
                                <p className="doctor_info_specialty">
                                    {doctor?.specialty
                                        ?.map((itm) => itm?.specialty)
                                        .join(", ")}
                                </p>

                                {doctor?.treatmentLink?.match(
                                    regLinkOfDoctor
                                ) ? (
                                    <ButtonLink
                                        text="Записатись"
                                        path={doctor?.treatmentLink}
                                    />
                                ) : (
                                    <p className="doctor_info_special_info">
                                        {doctor?.treatmentLink}
                                    </p>
                                )}
                                <p className="doctor_info_explanation">
                                    ЗВЕРТАЄМО УВАГУ! Підставою для надання
                                    послуг є електронне направлення від лікаря
                                    ПМД, з яким укладено декларацію, або
                                    лікуючого лікаря. САМОЗВЕРНЕННЯ можливе лише
                                    до лікаря акушер-гінеколога,
                                    лікаря-психіатра, лікаря-нарколога,
                                    лікаря-стоматолога, а також до лікаря, у
                                    якого пацієнт із хронічним захворюванням
                                    перебуває під медичним наглядом та
                                    самозвернення у невідкладному стані.
                                </p>
                                <div className="doctor_deep_info_box">
                                    <p className="doctor_deep_info_tit">
                                        Про лікаря
                                    </p>
                                    <p className="doctor_deep_info_education">
                                        Освіта: <span>{doctor?.education}</span>
                                    </p>
                                    <p className="doctor_deep_info_category">
                                        Категорія:{" "}
                                        <span>{doctor?.specialtyCategory}</span>
                                    </p>
                                    {viewDevision.map((elem) => (
                                        <Link
                                            className="mnp_link"
                                            to={`/devision/${elem._id}`}
                                            key={elem._id}
                                        >
                                            Місце надання послуг :{" "}
                                            <span>
                                                {elem?.location?.adress}
                                            </span>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* Doctors slider  */}
                        <div className="doctors_slider_wrapper">
                            <h2 className="doctor_block_tit">
                                Лікарі цієї ж спеціалізації
                            </h2>
                            <DoctorsSlider
                                specialty={doctor.specialty?.map(
                                    (itm) => itm?._id
                                )}
                                docId={doctor._id}
                            />
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Doctor;
