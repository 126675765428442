import React, { useEffect } from 'react';
import './Help.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import InformationAccordion from '../../components/InformationAccordion/InformationAccordion';
import PatronsSlider from '../../components/PatronsSlider/PatronsSlider';

const Help = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <section className='help_wrapper'>
            <div className='help_content'>

                {/* Head block */}
                <HeadBlock text='Потрібна допомога?'/>

                {/* Information accordion */}
                <div className='help_information_wrapper'>
                    <h2 className='help_title_text'>Корисна інформація для вас</h2>
                    <InformationAccordion/>
                </div>

                {/* Patrons slider */}
                <div className='patrons_wrapper'>
                    <h2 className='help_title_text'>Наші меценати</h2>
                    <PatronsSlider/>
                </div>

                {/* Help from us */}
                <div className='help_from_us_wrapper'>
                    <h2 className='help_title_text'>Ми допомагаємо!</h2>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}На базі нашого закладу функціонує спілка ветеранів медицини Кам’янець-Подільської міської громади, весь функціонал спілки забезпечують медичні працівники комунального некомерційного підприємства «Лікувальний діагностично-консультативний центр» Кам’янець-Подільської міської ради.</p>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}Підтримуючи людей літнього віку, ми розуміємо, що немає часу для самотнього життя без спілкування та можливості пізнавати нове.  Співпраця з Радою ветеранів нашої громади дає можливість вирішувати проблеми, які виникають у літніх людей. Спільні зустрічі в Раді ветеранів медичних працівників спонукають до активного життя, приносять багато нового в повсякдення кожного учасника.</p>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}Оновлені стандарти лікування лікарсько-стійкого туберкульозу (ЛС-ТБ) — стандарт передбачає регулювання питань щодо вибору сучасних режимів терапії із пріоритетом повністю пероральних схем лікування. Спеціалісти нашого закладу забезпечують безперервність лікування пацієнтів із хіміорезистентним туберкульозом. Пацієнтам більше не потрібно протягом багатьох місяців отримувати болісні ін’єкції. Нові стандарти медичної допомоги при туберкульозі дозволять ефективніше протидіяти хворобі. Їх головна мета — одужання для пацієнтів, зупинення розповсюдження туберкульозу та недопущення того, щоб хвороба отримала медикаментозну стійкість.</p>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}Хірургічне відділення забезпечує лікування пацієнтів в умовах денного стаціонару під наглядом провідних спеціалістів. Головна мета - швидке лікування для отримання максимально раннього відновлення здоров’я. Реабілітаційна допомога пацієнтам ортопедо-травматологічного, нейрохірургічного, неврологічного профілів, інвалідам, постраждалим від військової агресії також надається спеціалістами нашого закладу. Підтримка та забезпечення допоміжними засобами реабілітації (технічними та іншими засобами реабілітації) осіб з інвалідністю проводиться лікарями  при оформленні відповідних документів.</p>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}Наркологи некомерційного підприємства «Лікувальний діагностично-консультативний центр» Кам’янець-Подільської міської ради проводять лікування наркоманії. Це окремий етап комплексного лікування залежності від наркотиків, який передбачає проведення замісної підтримувальної терапії (метадонова програма), спрямованих на введення в організм наркозалежного спеціальних препаратів, основною метою яких є зняття ломки (абстинентного синдрому), виведення з організму залишків наркотичних засобів та їх похідних, а також зниження тяги до вживання.</p>
                    <p className='help_from_us_text'>{'\u00A0'}{'\u00A0'}{'\u00A0'}В нас функціонує кабінет «Довіри», кожен має можливість конфіденційно здати аналіз крові на ВІЛ-інфікування. Виявлення зараження вірусом на початкових етапах розвитку захворювання дає можливість вчасно почати лікування, визначити стадію хвороби, щоб спрогнозувати її подальший перебіг, для перевірки адекватності застосовуваної терапії, якщо вже підтверджений діагноз.</p>
                    <p className='help_from_us_text_for_ua'>{'\u00A0'}{'\u00A0'}{'\u00A0'}Продовжуємо працювати заради здоровʼя українців!</p>
                </div>
            </div>
        </section>
    );
};

export default Help;