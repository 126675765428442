import Swal from 'sweetalert2'

export const getPagination = (count, limit) => {
    let pageCount = Math.ceil(count / limit);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
};

export const clearEmptyProperty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== undefined));
};

export const getCookie = (name) => {
    var matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const deleteCookie = (name) => {
    document.cookie = name + '=; Max-Age=0'
}

export const getCurrentDate = () => {
    const date = new Date()
    return date.toLocaleDateString('uk-UA', { day: 'numeric', month: 'long' , year: 'numeric'})
}

export const alertForRequest = (reason, code) => {
    if (code !== 'ERR_BAD_REQUEST') {
        return Swal.fire({
            title: `<p class='swal_tit_txt'>${reason}</p>`,
            icon: 'success',
            confirmButtonColor: '#003b46',
            confirmButtonText: `<p class='swal_confitm_txt'>Ok</p>`,
            iconColor: '#003b46'
        })
    } else {
        return Swal.fire({
            title: `<p class='swal_tit_txt'>${reason}</p>`,
            icon: 'error',
            confirmButtonColor: '#ff0000b3',
            confirmButtonText: `<p class='swal_confitm_txt'>Ok</p>`,
            iconColor: '#ff0000b3'
        })
    }
}

export const alertForConfirmRequest = (func, value) => {
    Swal.fire({
        title: "Ви дійсно бажаєте здіснити видалення?",
        text: "Дана дія являється безповоротньою!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#003b46 ",
        cancelButtonColor: "#ff0000b3",
        confirmButtonText: "Так, підтверджую",
        cancelButtonText: "Скасувати"
    }).then((result) => {
        if (result.isConfirmed) {
            func(value)
        }
    });
}

// відмінювання
// input приклад (1) символ
// input2 приклад (2...4)символа
// input3 приклад (5...20) символів
export const declension = (num, input, input2, input3) => {
    let count = num % 100;
    if (count >= 5 && count <= 20) {
        return input3;
    } else {
        count = count % 10;
        if (count === 1) {
            return input;
        } else if (count >= 2 && count <= 4) {
            return input2;
        }
    }
    return input3;
};