import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './AdminInternships.scss'
import { apiInternships } from '../../../axios';
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { alertForConfirmRequest } from '../../../utils/helpers';
import { useModal } from '../../../components/Modals';
import Table from '../../../components/Table';
import { adminInternshipPost, adminInternshipUpdate, adminInternshipDelete } from '../../../axios/admin/manageInterships';
import { PAGE_LIMIT } from '../../../data/globalConstants';

const AdminInternships = () => {


    const dispatchModal = useModal();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    const getData = useCallback(async (paramsObj) => {
        try {
            setLoading(true);
            const result = await apiInternships.getInternships(paramsObj);           
            setData(result);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, []);

    const createHandler = useCallback(async (fieldsObj = {}) => {
        await adminInternshipPost(fieldsObj);
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search,
        });
    }, [getData, search, page]);

    const deleteHandler = useCallback(async (id) => {
        await adminInternshipDelete({
            id: id
        });
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const updateHandler = useCallback(async (fieldsObj) => {
        await adminInternshipUpdate(fieldsObj);
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const createOrUpdate = useCallback((obj = {}) => {
        dispatchModal('createEditInternship', { obj: obj, handleSuccess: (result) => obj?.id ? updateHandler(result) : createHandler(result) })
    }, [dispatchModal, createHandler, updateHandler]);

    useEffect(() => {
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const columns = useMemo(() => [
        {
            key: "nameSpecialty",
            children: "Назва інтернатури"
        },
        {
            key: "yearPrice",
            children: "Річна вартість"
        },
        {
            key: "years",
            children: "Термін інтернатури (років)"
        },
        {
            key: "controls",
            style: {
                width: 145
            },
            children: "Керування"
        }
    ], []);

    const dataRows = useMemo(() => data.map(itm => ({
        key: itm?._id,
        className: 'admin_table_row',
        rowData: [
            {
                children: itm?.nameSpecialty
            },
            {
                children: itm?.yearPrice
            },
            {
                children: itm?.years
            },
            {
                className: 'admin_controll_btn',
                children: <>
                    <button
                        className='admin_rewrite_btn'
                        onClick={() => createOrUpdate({
                            id: itm?._id,
                            nameSpecialty: itm?.nameSpecialty,
                            yearPrice: itm?.yearPrice,
                            years: itm?.years
                        })}
                    >
                        <FaPen />
                    </button>
                    <button
                        className='admin_delete_btn'
                        onClick={() => alertForConfirmRequest(deleteHandler, itm?._id)}
                    >
                        <MdDelete />
                    </button>
                </>
            }
        ]
    })), [data, createOrUpdate, deleteHandler]);

    const emptyText = useMemo(() => {
        return search === '' ? <div className='admin_empty_table'>
            Список інтернатури порожній
            <br />
            <button className='admin_create_element' onClick={() => createOrUpdate()}>Створити</button>
        </div> : `За пошуком "${search}" нікого не знайдено`
    }, [search, createOrUpdate]);

    return (
        <section className='admin_administration_wrapper'>
            <div className='admin_page_content'>
                <h2 className='admin_page_title'>
                    Інтернатура
                </h2>
                <div className='admin_filters_block'>
                    <input className='admin_search_input' type='text' placeholder='Пошук' onChange={e => setSearch(e.target.value)} />
                    <button className='admin_create_element' onClick={() => createOrUpdate()}>Створити</button>
                </div>

                <div className='admin_table_block'>
                    <Table
                        columns={columns}
                        data={dataRows}
                        loading={loading}
                        emptyText={emptyText}
                        pagination={{
                            page: page,
                            onChange: (number) => setPage(number),
                            pageLimit: PAGE_LIMIT
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default AdminInternships;