const navigationData = [
    {
        linkName: 'Лікарі',
        href: '/doctors'
    },
    {
        linkName: 'Графік роботи',
        href: '/devision'
    },
    {
        linkName: 'Послуги',
        href: '/services'
    },
    {
        linkName: 'Новини',
        href: '/news'
    },
    {
        linkName: 'Про поліклініку',
        href: '/about-cliniс'
    },
]

export default navigationData