import React from "react";
import { useController } from "react-hook-form";

const SelectForm = ({ name, control, rules = {}, disabled = false, defaultValue = '', ...props }) => {
    const { field, fieldState: { error } } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled
    });
    
    return <>
        <select
            className='admin_modal_label'
            {...field}
            {...props}
        >
            <option value={props.placeholder}>{props.placeholder}</option>
            {props.data?.map((elem,index)=> <option value={elem?.value ? elem?.value: elem } key={index}>{elem?.label ? elem?.label: elem}</option>)}
        </select>
        {error?.message && <p className="form_error_txt">{error?.message}</p>}
    </>
};

export default SelectForm;