import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Main.scss'
import mainDoctors from '../../img/main_doctors.jpeg'
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import Button from '../../components/Button/Button';
import Schedule from '../../components/Schedole/Schedule';
import Partners from '../../components/Partners/Partners';

const Main = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    })

    return (
        <section className='main_wrapper'>

                {/* Head block */}
                <section className='main_head_block section_block'>
                    <div className='main_head_content'>
                        <div className='main_head_title'>
                            <p className='main_head_title_sec'>Комунальне некомерційне підприємство</p>
                            <h1 className='main_head_title_txt'>
                                Лікувальний діагностично-консультативний центр
                            </h1>
                            <p className='main_head_title_sec'>Камʼянець-Подільської міської ради</p>
                        </div>
                        <div className='main_head_links'>
                            <Link className='main_head_link' to='/want-to-find'>Хочу знати</Link>
                            <Link className='main_head_link' to='/help'>Потрібна допомога</Link>
                            <Link className='main_head_link' to='/treatment'>Лікуватись</Link>
                        </div>
                    </div>
                </section>
                
                {/* News block */}
                <section className='main_news_block section_block'>
                    <div className='main_news_block_content'>
                        <p className='main_block_tit'>Останні новини</p>
                        <NewsSlider start={0} end={7}/>
                        <Button text={'Всі новини'} path={'news'}/>
                    </div>
                </section>

                {/* Schedole block */}
                <section className='main_schedole_block section_block'>
                    <div className='main_schedole_content'>
                        <p className='main_block_tit'>Місця надання послуг</p>
                        <Schedule link='devision'/>
                    </div>
                </section>

                {/* Doctors */}
                <section className='main_doctors_block section_block'>
                    <div className='main_doctors_content'>
                        <div className='main_doctors_info'>
                            <p className='main_doctors_block_tit'>Колектив</p>
                            <p className='main_doctors_block_sec'>Це команда висококваліфікованих професіоналів</p>
                            <Button text={'Наші лікарі'} path={'doctors'} />
                        </div>
                        <img className='main_doctors_img' src={mainDoctors} alt='Лікарі'/>
                    </div>
                </section>

                {/* Partners */}
                <section className='main_partners_block section_block'>
                    <div className='main_partners_content'>
                        <p className='main_block_tit'>В партнерстві</p>
                        <Partners/>
                    </div>
                </section>
        </section>
    );
};

export default Main;