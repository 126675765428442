import React from 'react';
import './Burger.scss';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    useDisclosure
} from '@chakra-ui/react';
import logo from '../../img/logo-dark-2.jpg'
import navigation from '../../data/navigationHeader'
import { Link } from 'react-router-dom';
import close from '../../img/close.svg'
import open from '../../img/menu.svg'
import contactsData from '../../data/contactsData';
import facebook from '../../img/facebook.svg'

const Burger = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <div className='wrapper_burger'>
            <img src={open} className='open_burger' onClick={onOpen} alt='Open'/>
            <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay onClick={onClose} className='burger_overlay'/>
                <DrawerContent className='burger_content'>
                    <DrawerHeader className='burger_header'>
                        <Link to='/' onClick={onClose} className='burger_logo_link'>
                            <img src={logo} alt='Logo' className="burger_logo"/>
                        </Link>
                        <img src={close} className="close_burger" onClick={onClose} alt='Close'/>
                    </DrawerHeader>
                    <hr className='burger_hr'/>
                    <DrawerBody className='burger_main_links'>
                        {navigation.map((link, index) => <Link className='burger_main_link' key={index} to={link.href} onClick={onClose}>{link.linkName}</Link>)}
                        <hr className='burger_hr'/>
                        <div className='burger_contacts'>
                            {contactsData.map((contact,index) => {
                                return <a href={`tel:${contact.phone}`} className='burger_social_contact_txt' key={index}>{contact.phone}</a>
                            })}
                            <a href='/' className='header_social_link'>
                                <img className='header_social_img' src={facebook} alt='Facebook'/>
                            </a>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default Burger;