import React, { useEffect, useState, memo, useCallback } from 'react';
import './Devision.scss'
import { useParams } from 'react-router-dom';
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import Loader from '../../components/Loader/Loader';
import DevisionInfo from '../../components/DevisionInfo/DevisionInfo';
import Doctors from '../../components/Doctors/Doctors';
import { apiDevisions } from '../../axios';

const Devision = () => {
    const { id } = useParams()
    const [devision, setDevision] = useState(null)

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevision(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData(id);
    }, [id, getData])

    return (
        <section className='devision_wrapper'>
            <div className='devision_content'>
                {devision === null ? <Loader /> : <>
                    {/* Head block */}
                    <HeadBlock text={devision?.nameDevision || ''} />

                    {/* Devision information */}
                    <section className='devision_information_wrapper'>
                        <h2 className='devision_block_tit'>
                            Інформація про місце надання послуг
                        </h2>
                        {/* Сюди передаємо обєкт devision щоб там не робити додатковий запит */}
                        <DevisionInfo devision={devision} />
                    </section>

                    {/* Devision doctors */}
                    <section className='devision_doctors_wrapper'>
                        <h2 className='devision_block_tit'>
                            Лікарі місця надання послуг
                        </h2>
                        <Doctors devisionId={devision?._id} />
                    </section>
                </>}
            </div>
        </section>
    );
};

export default memo(Devision);