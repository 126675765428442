import React from "react";
import { useController } from "react-hook-form";
import Select from "react-select";

const SelectMulti = ({ name, control, rules = {}, disabled = false, defaultValue = [], ...props }) => {
    const { field, fieldState: { error } } = useController({
        name,
        control,
        defaultValue,
        rules,
        disabled,
    });

    return <>
        <Select
            className='admin_modal_label'
            {...field}
            {...props}
            options={props.data}
            isMulti
        />
        {error?.message && <p className="form_error_txt">{error?.message}</p>}
    </>
};

export default SelectMulti;