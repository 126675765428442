import React from "react";
import "./Footer.scss";
import logo from "../../img/logo-dark-2.jpg";
import navigation from "../../data/navigationFooter";
import contacts from "../../data/contactsData";
import { Link } from "react-router-dom";
import facebook from "../../img/facebook.svg";
import email from "../../img/email.svg";

const Footer = () => {
    return (
        <footer className="footer_wrapper">
            <div className="footer_content">
                <div className="footer_logo">
                    <Link to="/">
                        <img className="footer_logo" src={logo} alt="Логотип" />
                    </Link>
                    {contacts.map((elem, index) => {
                        return (
                            <a
                                href={`tel:${elem.phone}`}
                                className="header_social_contact_txt"
                                key={index}
                            >
                                {elem.phone}
                            </a>
                        );
                    })}
                </div>
                <div className="footer_nav">
                    <nav className="footer_mav_list">
                        {navigation.map((elem, index) => {
                            return (
                                <li key={index}>
                                    <Link
                                        to={elem.href}
                                        className="navigation_phone"
                                        key={index}
                                    >
                                        {elem.linkName}
                                    </Link>
                                </li>
                            );
                        })}
                    </nav>
                </div>
                <div className="footer_social">
                    <p className="footer_social_txt">
                        Слідкуйте за нами в соціальних мережах
                    </p>
                    <a
                        href="https://www.facebook.com/profile.php?id=100014360437093"
                        target="_blank"
                        rel="noreferrer"
                        className="footer_social_link"
                    >
                        <img
                            className="footer_social_img"
                            src={facebook}
                            alt="Facebook"
                        />
                        Офіційна сторінка в Facebook
                    </a>
                    <p className="footer_social_txt">
                        Офіційна електронна скринька
                    </p>
                    <a
                        href="mailto:megapoliklinika@ukr.net"
                        className="footer_social_link"
                    >
                        <img
                            className="footer_social_img"
                            src={email}
                            alt="Email"
                        />
                        megapoliklinika@ukr.net
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
