import React from 'react';
import './Services.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import ServicesTabs from '../../components/ServicesTabs/ServicesTabs';

const Services = () => {
    return (
        <section className='services_wrapper'>
            <div className='services_content'>

                {/* Head block */}
                <HeadBlock text="Послуги"/>

                {/* Services tabs */}
                <section className='services_tab_box'>
                    <h2 className='services_block_tit'>Платні та безоплатні послуги</h2>
                    <p className='services_instruction_text'>
                        Безоплатні послуги надаються лише за наявності електронного направлення на послуги які входять в перелік безоплатних медичних послуг. Електронне направлення виписує лікуючий лікар — або лікар первинної ланки, або вузький спеціаліст. 
                    </p>
                    <ServicesTabs/>
                </section>
            </div>
        </section>
    );
};

export default Services;