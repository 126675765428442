import axios from "axios";
import { PATH } from '../data/apiPath';
import { clearEmptyProperty } from "../utils/helpers";

const getUser = async (user) => {
    return await new Promise((resolve, reject) => {
        let { userInfo = {} } = user && user !== null ? user : {};
        const clearFields = clearEmptyProperty(userInfo)
        let clearObj = {}
        if(Object.keys(clearFields).length > 0) clearObj = clearFields
        axios
            .post(`${PATH}/api/login`, 
                clearObj
            )
            .then(result => resolve(result.data))
            .catch(error => reject(error.response.data.message))
    });
};

export {
    getUser
};