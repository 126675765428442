import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ModalStore, modalMap } from './components/Modals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ModalStore modalMap={modalMap} destroyOnClose="afterClose">
      <App />
    </ModalStore>
  </BrowserRouter>
);

