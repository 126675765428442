import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminDoctorsPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/doctors`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Лікаря додано')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminDoctorsUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/doctors/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Лікаря оновлено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminDoctorsDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/doctors/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {

                if (res.status === 200) {
                    alertForRequest('Лікаря видалено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

export { adminDoctorsPost, adminDoctorsUpdate, adminDoctorsDelete }