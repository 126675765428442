import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './DoctorsSlider.scss'
import { apiDoctors } from '../../axios';
import { Link } from 'react-router-dom';

const DoctorsSlider = ({ specialty = [], docId}) => {
    
    const [doctors,setDoctors] = useState([])
    
    const getDataDoctors = useCallback(async (paramsObj) => {
        try {
            const result = await apiDoctors.getDoctors(paramsObj);
            setDoctors(result.filter(doc => doc._id !== docId));
        } catch (error) {
            console.log(error);
        }
    }, [docId]);

    useEffect(() => {
        getDataDoctors({
            filters: { "specialty": {
                '$in': specialty
            } }
        })
    },[getDataDoctors, specialty])

    return (
        <>
            {doctors?.length !== 0 ? 
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    autoplay={{
                        delay: 2750,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination,Autoplay]}
                    className="doctorsSlider"
                >
                    {doctors.map((element,index) => {
                            return <SwiperSlide key={index}>
                                        <div className='doctor_block_slide' key={index}>
                                            <Link className='doctor_block_slide_box' to={'/doctors/'+element._id}>
                                                <div className='doctor_block_info_wrapper'>
                                                    <p className='doctor_block_info_name'>{element.firstName} {element.secondName} {element.surname}</p>
                                                    <hr noshade='true'/>
                                                    <p className='doctor_block_info_specialty'>{element.specialty?.map(itm=>itm?.specialty).join(', ')}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                        })
                    }
                </Swiper>
                :
                <p className='spec_doc_tit_text'>Інші лікарі даної спеціалізації нажаль відсутні</p>
            }
        </>
    );
};

export default DoctorsSlider;