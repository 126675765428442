import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import './PatronsSlider.scss'
import 'swiper/css';
import 'swiper/css/pagination';
import Loader from '../Loader/Loader';
import { apiPatrons } from '../../axios';

const PatronsSlider = () => {

    const [patrons,setPatrons] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiPatrons.getPatrons(paramsObj);
            setPatrons(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData()
    },[getData])

    return (
        <div className='patrons_slider_wrapper'>
            <div className='patrons_slider_contetn'>
                {patrons.length !== 0 ? 
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            500: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                            },
                        }}
                        autoplay={{
                            delay: 2750,
                            disableOnInteraction: false,
                        }}
                        modules={[Pagination,Autoplay]}
                        className="newsSlider"
                    >
                        {patrons.map((patron,index) => {
                                return <SwiperSlide key={index}>
                                            <div className='patron_box'>
                                                <p className='patron_name'>{patron.patronName}</p>
                                            </div>
                                        </SwiperSlide>
                            })
                        }
                    </Swiper>
                    :
                    <Loader/>
                }
            </div>
        </div>
    );
};

export default PatronsSlider;