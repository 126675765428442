import React, { useCallback, useEffect, useState } from "react";
import "./ServicesTabs.scss";
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    ChakraProvider,
} from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th } from "@chakra-ui/react";
import Loader from "../Loader/Loader";
import { apiServices } from "../../axios";

const ServicesTabs = () => {
    const [services, setServices] = useState([]);

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiServices.getServices(paramsObj);
            setServices(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div className="services_tab_wrapper">
            <div className="services_tab_content">
                {services.length !== 0 ? (
                    <ChakraProvider>
                        <Tabs variant="enclosed">
                            <TabList className="tab_list">
                                <Tab>Платні послуги</Tab>
                                <Tab>Безоплатні послуги</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Table>
                                        <Thead className="tab_head">
                                            <Tr>
                                                <Th>Послуга</Th>
                                                <Th>Вартість (ціна в грн.)</Th>
                                            </Tr>
                                        </Thead>
                                        {services
                                            .filter(
                                                (service) =>
                                                    service.paidService ===
                                                    "Платно"
                                            )
                                            .map((service, index) => (
                                                <Tbody key={index}>
                                                    <Tr className="tab_row">
                                                        <Th>
                                                            {
                                                                service.serviceName
                                                            }
                                                        </Th>
                                                        <Th>{service.price}</Th>
                                                    </Tr>
                                                </Tbody>
                                            ))}
                                    </Table>
                                </TabPanel>
                                <TabPanel>
                                    <Table>
                                        <Thead className="tab_head">
                                            <Tr>
                                                <Th>Послуга</Th>
                                                <Th>Вартість (ціна в грн.)</Th>
                                            </Tr>
                                        </Thead>
                                        {services
                                            .filter(
                                                (service) =>
                                                    service.paidService ===
                                                    "Безоплатно"
                                            )
                                            .map((service, index) => (
                                                <Tbody key={index}>
                                                    <Tr className="tab_row">
                                                        <Th>
                                                            {
                                                                service.serviceName
                                                            }
                                                        </Th>
                                                        <Th>
                                                            {
                                                                service.paidService
                                                            }
                                                        </Th>
                                                    </Tr>
                                                </Tbody>
                                            ))}
                                    </Table>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ChakraProvider>
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

export default ServicesTabs;
