import React, { useMemo } from "react";
import { Table as ATable, Thead, Tbody, Tr, Th, Td, TableContainer, ChakraProvider } from '@chakra-ui/react';
import Loader from "../Loader/Loader";
import { getPagination } from "../../utils/helpers";
import { PAGE_LIMIT } from '../../data/globalConstants';

const Table = ({ data = [], columns = [], loading = false, emptyText = 'Данних ще немає', pagination = {} }) => {

    const { count = 0, page = 1, onChange = () => { }, pageLimit = PAGE_LIMIT } = pagination;
    const allPages = useMemo(() => getPagination(count, pageLimit), [count, pageLimit]);

    return <>
        {loading && <Loader />}
        <ChakraProvider>
            <TableContainer>
                <ATable variant='striped'>
                    <Thead>
                        <Tr className='admin_table_head'>
                            {columns.map(col => <Th {...col} />)}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map(({ rowData = [], ...row }) => <Tr {...row}>
                            {rowData.map((data, index) => <Td key={index} {...data} />)}
                        </Tr>)}
                        {data.length === 0 && !loading && <Tr className="admin_table_row">
                            <Td colSpan={columns.length}>{emptyText}</Td>
                        </Tr>}
                    </Tbody>
                </ATable>
            </TableContainer>
        </ChakraProvider>
        {allPages.length > 1 && <div className='news_pagination'>
            {allPages.map((elem, index) => <p className={page === elem ? 'actived_pagination' : ''} key={index} onClick={() => onChange(elem)}>{elem}</p>)}
        </div>}
    </>;
};

export default Table;