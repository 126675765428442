import React from 'react';
import './Header.scss'
import { Link } from 'react-router-dom';
import logo from '../../img/logo-dark-2.jpg'
import navigationData from '../../data/navigationHeader';
import contactsData from '../../data/contactsData';
import facebook from '../../img/facebook.svg'
import Burger from '../Burger-menu/Burger';

const Header = () => {

    return (
        <header className='header_wrapper'>
            <div className='header_content'>
                <div className='header_logo'>
                    <Link to='/'>
                        <img className='header_logo_img' src={logo} alt='Logo'/>
                    </Link>
                </div>
                <nav className='header_nav'>
                    <ul className='header_nav_list'>
                        {navigationData.map((link,index) => {
                            return <Link to={link.href} key={index}>{link.linkName}</Link>
                        })}
                    </ul>
                </nav>
                <div className='header_social'>
                    <div className='header_social_contact'>
                        {contactsData.map((contact,index) => {
                            return <a href={`tel:${contact.phone}`} className='header_social_contact_txt' key={index}>{contact.phone}</a>
                        })}
                    </div>
                    <a href='https://www.facebook.com/profile.php?id=100014360437093' target='_blank' rel='noreferrer' className='header_social_link'>
                        <img className='header_social_img' src={facebook} alt='Facebook'/>
                    </a>
                </div>
                <Burger/>
            </div>
        </header>
    );
};

export default Header;