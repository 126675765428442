import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminPartnersPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/partners`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Запитання додано')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminPartnersUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/partners/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Запитання оновлено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminPartnersDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/partners/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {

                if (res.status === 200) {
                    alertForRequest('Запитання видалено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

export { adminPartnersPost, adminPartnersUpdate, adminPartnersDelete }