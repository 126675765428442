import * as yup from "yup";
import { declension } from "../../../../utils/helpers";

const REQUIRED_MESSAGE = 'Поле обовʼязкове для заповнення';
const MIN_MESSAGE = (number = 1) => `Мінімум ${number} ${declension(number, 'символ', 'символа', 'символів')}`;

const schema = yup.object().shape({
    vacancyName: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE(3)),
    descr: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE(3)),
});

export default schema;