import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ChakraProvider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import schema from "./schema";
import { Input } from "../../../FormElement";
import { apiDevisions } from '../../../../axios';
import SelectMulti from '../../../FormElement/components/SelectMulti';

const CreateEditSpecialties = ({ visible, onCancel, obj = null, handleSuccess }) => {

    const [devision, setDevision] = useState([])

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false
    })

    const onSubmit = data => {
        handleSuccess({
            ...data,
            devision: data?.devision?.map(itm=>itm?.value)
        });
        onCancel();
    };

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getData({});
        reset({
            ...obj,
            devision: obj?.devision?.map(itm => ({
                label: itm?.nameDevision,
                value: itm?._id
            }))
        });
        return () => {
            reset({});
        };
    }, [reset, obj, getData]);

    const optionsDevisions = useMemo(() => devision.map(item => ({
        label: item?.nameDevision,
        value: item?._id,
    })), [devision]);

    return <ChakraProvider>
        <Modal isOpen={visible} size='lg' onClose={onCancel}>
            <ModalOverlay className='admin_modal_overlay' />
            <ModalContent>
                <ModalHeader className='admin_modal_tit'>Спеціальність</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form className='admin_administration_modal'
                        onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            name={"specialty"}
                            control={control}
                            placeholder='Назва спеціальності'
                        />
                        <SelectMulti
                            name={"devision"}
                            control={control}
                            placeholder='Місце надання послуг'
                            data={optionsDevisions}
                        />
                        <div className='admin_modal_btns'>
                            <input className='admin_modal_save_btn' value={obj?.id ? 'Оновити' : 'Зберегти'} type="submit" />
                            <button className='admin_modal_cancel_btn' type='button' onClick={onCancel}>
                                Скасувати
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    </ChakraProvider>;
};

export default CreateEditSpecialties;