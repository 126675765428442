import * as yup from "yup";
import { declension } from "../../../../utils/helpers";

const REQUIRED_MESSAGE = 'Поле обовʼязкове для заповнення';
const LINK_RULES = 'Посилання на зображення повинно починатись на http:// або https://'
const MIN_MESSAGE = (number = 1) => `Мінімум ${number} ${declension(number, 'символ', 'символа', 'символів')}`;

const schema = yup.object().shape({
    title: yup.string().trim().required(REQUIRED_MESSAGE).min(3, MIN_MESSAGE(3)),
    // description: yup.string().trim().required(REQUIRED_MESSAGE).min(10, MIN_MESSAGE(10)),
    image: yup.string().trim().required(REQUIRED_MESSAGE).matches(/^(https?|ftp):\/\//, LINK_RULES)})

export default schema;