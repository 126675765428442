import { Route, Routes } from 'react-router-dom';
import './App.css';
import Context from './context/Context';
import Layout from './components/Layout/Layout';
import Main from './pages/Main/Main';
import Login from './pages/Admin/Login/Login';
import AdminLayout from './components/Layout/AdminLayout';
import ProtectedRoute from './protect-route/ProtectRoute';
import AdminPanel from './pages/Admin/AdminPanel/AdminPanel';
import mainNavigationData from './data/mainNavigationData';
import adminNavigationData from './data/adminNavigationData';
import Error from './pages/Error/Error';

function App() {

  return (
    <Context.Provider value={{}}>
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Main/>}/>
          {mainNavigationData.map((route,index) => {
            return <Route path={route?.href} element={route?.component} key={index}/>
          })}
          <Route path='*' element={<Error/>}/>
        </Route>
        <Route path='/login' element={<Login/>}/>
        <Route path='/admin-panel' element={<AdminLayout/>}>
          <Route index element={<ProtectedRoute component={<AdminPanel/>}/>}/>
          {adminNavigationData.map((route,index) => {
            return <Route path={route?.href} element={<ProtectedRoute component={route?.component}/>} key={index}/>
          })}
          <Route path='*' element={<Error/>}/>
        </Route>
      </Routes>
    </Context.Provider>
  )
}

export default App;
