import React, { useCallback, useEffect, useState } from 'react';
import './WantToFind.scss'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import ServicesTabs from '../../components/ServicesTabs/ServicesTabs';
import contactsData from '../../data/contactsData';
import InternshipAccordion from '../../components/InternshipAccordion/InternshipAccordion';
import { apiVacancies } from '../../axios';

const WantToFind = () => {

    const [vacancies, setVacancies] = useState([])

    const getData = useCallback(async (paramsObj) => {
        try {
            const result = await apiVacancies.getVacancies(paramsObj);
            setVacancies(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData()
    }, [getData])

    return (
        <section className='want_to_find_wrapper'>
            <section className='want_to_find_content'>

                {/* Head block */}
                <HeadBlock text='Корисна інформація' />

                {/* Services block */}
                <section className='want_to_find_services_block'>
                    <h2 className='want_to_find_block_tit'>Послуги</h2>
                    <p className='want_to_find_services_block_sec_txt'>
                        Перелік послуг які надають в закладі на <span>платній основі</span>, а також на <span>безкоштовній основі</span>. Для отримання послуг на <span>безкоштовній основі</span> необхідно отримати електронне направлення від вузького спеціаліста , або від лікаря первинного рівня (сімейний лікар, терапевт, педіатр).
                    </p>
                    <Accordion allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton className='want_to_find_accord_btn'>
                                    <div>
                                        Детальніше про послуги
                                    </div>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <ServicesTabs />
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </section>

                {/* VPO block */}
                <section className='want_to_find_vpo_help_block'>
                    <h2 className='want_to_find_block_tit'>Медична допомога ВПО</h2>
                    <div className='want_to_find_vpo_help_content'>
                        <p className='want_to_find_vpo_help_text'>
                            На час воєнного стану в Україні кожен українець має право звернутись по медичну допомогу в комунальне некомерційне підприємство «Лікувальний діагностично-консультативний центр» Кам’янець-Подільської міської ради незалежно від місця підписання декларації для отримання відповідних медичних послуг згідно договорів з НСЗУ. З 24 лютого 2022 року у наш заклад звернулося 12 712 внутрішньо переміщених осіб. <br /><br />
                            Внутрішньо переміщена особа, яка потребує спеціалізованої медичної допомоги (онкологічної, кардіологічної, терапевтичної, психологічної тощо), повинна звернутися до сімейного лікаря та отримати направлення до лікарів відповідного профілю нашого медичного закладу.<br /><br />
                            Також наші медичні працівники сприяють людям з обмеженими можливостями отримати, переоформити відповідні документи для продовження їх статусу з отриманням всіх відповідних видів допомоги. <br /><br />
                            Безперервне лікування хронічних захворювань є одним із основних напрямків для нас, щоб зберегти та зміцнити здоров’я пацієнта.<br /><br />
                            Ми створюємо умови для ефективного і доступного для всіх громадян медичного обслуговування. Внутрішньо переміщена особа має право на забезпечення лікарськими засобами у випадках та порядку, визначених законодавством.<br /><br />
                            В аптеках продовжує працювати програма  «Доступні ліки». В рамках цієї програми всі препарати за рецептом для людей, які потребують постійного лікування, можна отримати в будь-якій аптеці безкоштовно. Якщо ліки перевищують визначену державою ціну, то їх можна отримати за невелику доплату. Перерозподіл таких ліків по регіонах регулює Міністерство охорони здоров’я.
                        </p>
                        <a className='want_to_find_vpo_help_btn' href='https://minre.gov.ua/informacziya/korysna-informacziya/korysna-informacziya-dlya-vpo/bezkoshtovni-liky-ta-medychna-dopomoga-dlya-vpo-yak-otrymaty/' target='_blank' rel='noreferrer'>Детальніше про допомогу та лікування</a>
                    </div>
                </section>

                {/* Vacancies block */}
                <section className='want_to_find_vacancies_block'>
                    <div className='want_to_find_vacancies_content'>
                        <h2 className='want_to_find_block_tit'>Відкриті вакансії</h2>
                        {vacancies.length !== 0 ?
                            <>
                                <div className='want_to_find_vacancies_contacts'>
                                    <p className='want_to_find_vacancies_contacts_txt'>З питань вакансій <br /> зверніться за телефонами:</p>
                                    <div className='want_to_find_vacancies_contacts_box'>
                                        {contactsData.map((contact, index) => {
                                            return <a href={`tel:${contact.phone}`} className='want_to_find_contact_txt' key={index}>{contact.phone}</a>
                                        })}
                                    </div>
                                </div>
                                <div className='want_to_find_vacancies'>
                                    {vacancies.map((element, index) => {
                                        return <div className='want_to_find_vacancies_box' key={index}>
                                            <p className='want_to_find_vacancies_box_tit'>{element.vacancyName}</p>
                                            <hr noshade='true' />
                                            <p className='want_to_find_vacancies_box_sec_txt'>{element.descr}</p>
                                        </div>
                                    })}
                                </div>
                            </>
                            :
                            <p className='empty_vacancies'>Вакансії відсутні</p>
                        }
                    </div>
                </section>

                {/* Internship */}
                <section className='want_to_find_internship_block'>
                    <div className='want_to_find_internship_content'>
                        <h2 className='want_to_find_block_tit'>Стажування та інтернатура</h2>
                        <p className='want_to_find_block_sec_txt'>
                            Зарахування випускників медичних спеціальностей на інтернатуру до  комунального некомерційного підприємства «Лікувальний діагностично-консультативний центр» Кам’янець-Подільської міської ради <br /><br />
                            Випускники-контрактники отримують довідку про форму навчання та самостійно обирають місце проходження інтернатури.
                            Випускник, який отримав довідку про самостійне обрання місця проходження інтернатури, самостійно обирає нашу базу стажування,  звертається до директора  комунального некомерційного підприємства «Лікувальний діагностично-консультативний центр» Кам’янець-Подільської міської ради та, у разі підтвердження наявності вакантної посади або місця для проходження інтернатури, подає документи для зарахування в інтернатуру.
                            Фінансування підготовки в інтернатурі відбувається за кошти фізичних (юридичних) осіб на підставі договорів про надання платних послуг із випускником-контрактником та базою стажування. <br /><br />
                            Кількість місць на підготовку лікарів-інтернів визначається МОЗ з урахуванням:<br />
                            - кадрових потреб, сформованих на підставі заявок ДОЗ;<br />
                            - обсягу бюджетних призначень, передбачених на відповідний рік.<br />

                        </p>
                        <InternshipAccordion />
                    </div>
                </section>
            </section>
        </section>
    );
};

export default WantToFind;