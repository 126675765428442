import React from 'react';
import { Link } from 'react-router-dom';
import './AdminAside.scss'
import adminNavigationData from '../../../data/adminNavigationData';

const AdminAside = () => {
    return (
        <aside className='admin_aside_wrapper'>
            {adminNavigationData.map((elem,index) => {
                return <div className='admin_aside_link_box' key={index}>
                            <Link className='admin_aside_link' to={elem?.href}>{elem?.linkName}</Link>
                            {elem?.image}
                        </div>
            })}
        </aside>
    );
};

export default AdminAside;