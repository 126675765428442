import React, { useCallback, useEffect, useState } from "react";
import "./AboutCliniс.scss";
import HeadBlock from "../../components/HeadBlock/HeadBlock";
import {
    apiAdministration,
    apiDevisions,
    apiDocuments,
    apiSpecialties,
} from "../../axios";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";

const AboutCliniс = () => {
    const [devisions, setDevision] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [administration, setAdministration] = useState([]);

    const getDataAdministration = useCallback(async (paramsObj) => {
        try {
            const result = await apiAdministration.getAdministration(paramsObj);
            setAdministration(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataDocuments = useCallback(async (paramsObj) => {
        try {
            const result = await apiDocuments.getDocuments(paramsObj);
            setDocuments(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataDevision = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevision(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataSpecialties = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getDataDevision();
        getDataSpecialties();
        getDataAdministration();
        getDataDocuments();
    }, [
        getDataDevision,
        getDataSpecialties,
        getDataAdministration,
        getDataDocuments,
    ]);

    return (
        <section className="about_cliniс_wrapper">
            <div className="about_cliniс_content">
                {/* Header block */}
                <HeadBlock text={"Про поліклініку"} />

                <section className="about_clinic_txt_wrapper">
                    <h2 className="about_clinic_tit_block">Про нас</h2>
                    <p className="about_clinic_tit_txt">
                        {"\u00A0"}
                        {"\u00A0"}Комунальне некомерційне підприємство
                        «Лікувальний діагностично-консультативний центр»
                        Кам’янець-Подільської міської ради є багатопрофільним
                        лікувально-профілактичним закладом, який надає
                        амбулаторну медичну допомогу, вторинну (спеціалізовану)
                        консультативно-діагностичну, інші види медичної допомоги
                        населенню відповідно до вимог чинного законодавства,
                        галузевих стандартів надання медичної допомоги.
                        Підприємство реорганізоване за рішенням
                        Кам’янець-Подільської міської ради з міської поліклініки
                        №1 в КНП «ЛДКЦ» КП МР в 2019 році. Юридична адреса:
                        Хмельницька область, м.Кам’янець-Подільський, вулиця
                        Огієнка, 20.
                    </p>
                    <p className="about_clinic_tit_txt">
                        {"\u00A0"}
                        {"\u00A0"}В комунальному некомерційному підприємстві
                        «Лікувальний діагностично-консультативний центр»
                        Кам’янець-Подільської міської ради надають послуги 68
                        лікарів-професіоналів з багаторічним досвідом роботи,
                        щорічно відвідують близько 88 тис пацієнтів. Наш заклад
                        постійно працює над удосконаленням та розширенням
                        спектру лікувальних послуг. Так, лише протягом минулих
                        років вдалося: ввести в експлуатацію та оцифрувати новий
                        мамограф та рентгенапарат ; впровадження нових
                        технологій в стоматології, зокрема загального знеболення
                        при виконанні хірургічних втручань різного характеру за
                        медичними показами і бажанням пацієнта; розвиваємо
                        напрямок «Нетрадиційна медицина». З року в рік
                        покращується матеріально-технічне оснащення
                        підприємства.  В закладі запроваджено роботу електронної
                        реєстратури. Кабінети оснащено ПК та копіювальною
                        технікою, що дало змогу лікарям в умовах реформи
                        медичної галузі «йти в ногу з часом» – проводити запис
                        пацієнтів на прийом до лікарів спеціалістів чи на
                        повторний прийом до себе не виходячи з кабінету.
                    </p>
                </section>

                {/* Clinic structure */}
                <section className="about_clinic_structure_wrapper">
                    <h2 className="about_clinic_tit_block">
                        Струкрура поліклініки
                    </h2>
                    {devisions.length !== 0 ? (
                        <>
                            <div className="about_clinic_structure_list">
                                {devisions.map((devision) => {
                                    return (
                                        <Button
                                            path={`devision/${devision?._id}`}
                                            text={devision?.location?.adress}
                                            key={devision?._id}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <Loader />
                    )}
                </section>

                {/* Clinic specialties */}
                <section className="about_clinic_specialties_wrapper">
                    <h2 className="about_clinic_tit_block">
                        Спеціалісти амбулаторно-поліклінічної допомоги
                    </h2>
                    {specialties.length !== 0 ? (
                        <>
                            <ul className="about_clinic_specialties_list">
                                {specialties.map((specialty) => {
                                    return (
                                        <li key={specialty?._id}>
                                            {specialty?.specialty}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    ) : (
                        <Loader />
                    )}
                </section>

                {/* Clinic services */}
                <section className="about_clinic_services_wrapper">
                    <h2 className="about_clinic_tit_block">
                        Спектр медичних послуг
                    </h2>
                    <p className="about_clinic_services_text">
                        {"\u00A0"}
                        {"\u00A0"}Профілактичні медичні огляди громадян, видача
                        медичних довідок для отримання дозволу на право носіння
                        зброї та керування автомобілем, різні види
                        маніпуляційних послуг в амбулаторних та домашніх умовах,
                        проходження інтернатури на контрактних умовах,
                        стоматологічні послуги,аудіометрія, колоноскопія,
                        бронхоскопія, холтерівський моніторинг ЕКГ,
                        езофагогастродуоденоскопія, флюорографія, УЗД,
                        голкотерапія, різні види аналізів, проведення
                        профілактичних досліджень на виявлення збудників
                        захворювань, косметичні процедури, масаж, мануальна
                        терапія тощо. Ми стоїмо на варті життя та здоровʼя наших
                        пацієнтів. Саме задля Вас закуповуємо сучасне
                        обладнання, щоденно працюємо над покращенням сервісу та
                        якості медичних послуг.
                    </p>
                </section>

                {/* Administration clinic */}
                <section className="about_clinic_administration_wrapper">
                    <h2 className="about_clinic_tit_block">
                        Адміністрація закладу
                    </h2>
                    <div className="about_clinic_administration_content">
                        {administration.length !== 0 ? (
                            <>
                                {administration.map((person) => {
                                    return (
                                        <div
                                            className="administration_box"
                                            key={person?._id}
                                        >
                                            <p className="admin_person_position">
                                                {person?.position}
                                            </p>
                                            <hr noshade="true" />
                                            <p className="admin_person_name">
                                                {person?.firstName}{" "}
                                                {person?.secondName}{" "}
                                                {person?.surname}
                                            </p>
                                            <a
                                                href={`tel:${person.phone}`}
                                                className="admin_person_phone"
                                            >
                                                {person.phone}
                                            </a>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </section>
                {/* Documents block */}
                <section className="about_clinic_documents_wrapper">
                    <h2 className="about_clinic_tit_block">
                        Установчі документи
                    </h2>
                    <div className="about_clinic_documents_content">
                        {documents.length !== 0 ? (
                            <ul className="documents_list">
                                {documents.map((document) => {
                                    return (
                                        <li
                                            className="document_box"
                                            key={document?._id}
                                        >
                                            <a
                                                href={document?.link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {document?.name}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <Loader />
                        )}
                    </div>
                </section>
            </div>
        </section>
    );
};

export default AboutCliniс;
