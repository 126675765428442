import React, { useCallback, useEffect, useState } from 'react';
import './Schedule.scss'
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import { apiDevisions, apiSpecialties } from '../../axios';

const Schedole = () => {

    const [devisions, setDevisions] = useState([])
    const [specialties, setSpecialties] = useState([])

    const getDataDevisions = useCallback(async (paramsObj) => {
        try {
            const result = await apiSpecialties.getSlecialties(paramsObj);
            setSpecialties(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const getDataSpecialties = useCallback(async (paramsObj) => {
        try {
            const result = await apiDevisions.getDevisions(paramsObj);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        getDataDevisions()
        getDataSpecialties({
            limit : 30
        })
    }, [getDataDevisions, getDataSpecialties])

    return (
        <div className='schedole_wrapper'>
            <div className='schedole_content'>
                {devisions.length !== 0 ?
                    devisions.map((devision, index) => {
                        return <div className='schedole_box' key={index}>
                            <Link className='schedule_link' to={`/devision/${devision._id}`}>
                                <div className='devision_info'>
                                    <p className='devision_title'>{devision.nameDevision}</p>
                                    <p className='devision_adress'>{devision.location.adress}</p>
                                </div>
                                <hr noshade='true' />
                                <div className='devision_schedune'>
                                    <p>ПН-ПТ: <span>{devision.schedule.weekdays}</span></p>
                                    <p>СБ: <span>{devision.schedule.saturday}</span></p>
                                    <p>НД: <span>{devision.schedule.sunday}</span></p>
                                </div>
                            </Link>
                            <Accordion allowToggle>
                                <AccordionItem>
                                    <AccordionButton className='schedule_specialties_btn'>
                                        <div>Спеціалісти</div>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4} className='schedule_specialties_accord_body'>
                                        <ul className='schedule_specialties_list'>
                                            {specialties.map((specialty, index) =>{
                                                // eslint-disable-next-line
                                                return specialty.devision.map(itm => {
                                                    if (itm._id === devision._id){
                                                        return <li key={index}>{specialty?.specialty}</li>
                                                    }
                                                })  
                                            })}
                                        </ul>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    })
                    :
                    <Loader />
                }
            </div>
        </div>
    );
};

export default Schedole;