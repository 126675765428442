import React, { useEffect } from "react";
import {
    ChakraProvider,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import schema from "./schema";
import { Input, PhoneInput } from "../../../FormElement";
import CoordinatesInput from "../../../FormElement/components/Coordinates";
import TimeInput from "../../../FormElement/components/Time";

const CreateEditDevisions = ({ visible, onCancel, obj = null, handleSuccess }) => {
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
        shouldUnregister: false
    })

    const onSubmit = data => {
        handleSuccess(data);
        onCancel();
    };

    useEffect(() => {
        reset(obj);
        return () => {
            reset({});
        };
    }, [reset, obj]);

    return <ChakraProvider>
        <Modal isOpen={visible} size='lg' onClose={onCancel}>
            <ModalOverlay className='admin_modal_overlay' />
            <ModalContent>
                <ModalHeader className='admin_modal_tit'>Місце надання послуг</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <form className='admin_administration_modal' 
                        onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            name={"nameDevision"}
                            control={control}
                            placeholder='Назва МНП'
                        />
                        <CoordinatesInput
                            name={"location.lt"}
                            control={control}
                            placeholder='Широта'
                        />
                        <CoordinatesInput 
                            name={"location.ln"}
                            control={control}
                            placeholder='Довгота'
                        />
                        <Input
                            name={"location.adress"}
                            control={control}
                            placeholder='Адреса'
                        />
                        <TimeInput
                            name={"schedule.weekdays"}
                            control={control}
                            placeholder='Будні дні'
                        />
                        <TimeInput
                            name={"schedule.saturday"}
                            control={control}
                            placeholder='Субота'
                        />
                        <TimeInput
                            name={"schedule.sunday"}
                            control={control}
                            placeholder='Неділя'
                        />
                        <PhoneInput
                            name={"phone.mobile"}
                            control={control}
                            placeholder='Мобільний'
                        />
                        <PhoneInput
                            name={"phone.stac"}
                            control={control}
                            placeholder='Стаціонарний'
                        />
                        
                        <div className='admin_modal_btns'>
                            <input className='admin_modal_save_btn' value={obj?.id ? 'Оновити' : 'Зберегти'} type="submit" />
                            <button className='admin_modal_cancel_btn' type='button' onClick={onCancel}>
                                Скасувати
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    </ChakraProvider>;
};

export default CreateEditDevisions;