import axios from "axios";
import { PATH } from "../../data/apiPath";
import { alertForRequest, getCookie } from "../../utils/helpers";

const adminSpecialtyPost = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(`${PATH}/api/specialty`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Спеціальність додано')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminSpecialtyUpdate = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .patch(`${PATH}/api/specialty/${fieldsObj?.id}`, fieldsObj, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    alertForRequest('Спеціальність оновлено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

const adminSpecialtyDelete = async (fieldsObj) => {
    return await new Promise((resolve, reject) => {
        axios
            .delete(`${PATH}/api/specialty/${fieldsObj?.id}`, {
                headers: {
                    token: getCookie('tk')
                }
            })
            .then((res) => {

                if (res.status === 200) {
                    alertForRequest('Спеціальність видалено')
                    resolve(res.data)
                }
            })
            .catch(err => {
                alertForRequest(err.response.data.message, err.code)
                reject(err)
            })
    })
}

export { adminSpecialtyPost, adminSpecialtyUpdate, adminSpecialtyDelete }