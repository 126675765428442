import axios from "axios";
import { PATH } from '../data/apiPath';
import { clearEmptyProperty } from "../utils/helpers";

const getDoctor = async (id = null) => {
    return await new Promise((resolve, reject) => {
        axios
            .get(`${PATH}/api/doctors/${id}`)
            .then(result => resolve(result.data))
            .catch(error => reject(error))
    });
};

const getDoctors = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let { limit = 20, page = 1, filters = {}, fio = '' } = rules && rules !== null ? rules : {};
        params.append('limit', limit);
        params.append('page', page);
        if(fio && fio !== '') params.append('fio', fio);
        const clearFilters = clearEmptyProperty(filters);
        if(Object.keys(clearFilters).length > 0)  params.append('filters', JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/doctors`, {
                params
            })
            .then(result => resolve(result.data))
            .catch(error => reject(error))
    });
};

const getDoctorsCount = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let { filters = {}, fio = '' } = rules && rules !== null ? rules : {};
        if(fio && fio !== '') params.append('fio', fio);
        const clearFilters = clearEmptyProperty(filters);
        if(Object.keys(clearFilters).length > 0)  params.append('filters', JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/doctorsCount`, {
                params
            })
            .then(result => resolve(result.data))
            .catch(error => reject(error))
    });
};

export {
    getDoctor,
    getDoctors,
    getDoctorsCount
};