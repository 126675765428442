import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './AdminDevisions.scss'
import { apiDevisions } from '../../../axios';
import { MdDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { alertForConfirmRequest } from '../../../utils/helpers';
import { useModal } from '../../../components/Modals';
import Table from '../../../components/Table';
import { adminDevisionPost, adminDevisionUpdate, adminDevisionDelete } from '../../../axios/admin/manageDevisions';
import { PAGE_LIMIT } from '../../../data/globalConstants';

const AdminDevisions = () => {
    
    const dispatchModal = useModal();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    const getData = useCallback(async (paramsObj) => {
        try {
            setLoading(true);
            const result = await apiDevisions.getDevisions(paramsObj);
            setData(result);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    },[]);

    const createHandler = useCallback(async (fieldsObj = {}) => {
        await adminDevisionPost({
            ...fieldsObj,
            schedule: {
                ...fieldsObj?.schedule,
                saturday: fieldsObj?.schedule?.saturday?.length < 11 ? "Вихідний" : fieldsObj?.schedule?.saturday,
                sunday: fieldsObj?.schedule?.sunday?.length < 11 ? "Вихідний" : fieldsObj?.schedule?.sunday
            }
        });
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search,
        });
    }, [getData, search, page]);

    const deleteHandler = useCallback(async (id) => {
        await adminDevisionDelete({
            id: id
        });
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const updateHandler = useCallback(async (fieldsObj) => {;
        console.log(fieldsObj);
        await adminDevisionUpdate({
            id: fieldsObj?.id,
            ...fieldsObj,
            schedule: {
                ...fieldsObj?.schedule,
                saturday: fieldsObj?.schedule?.saturday?.length < 11 ? "Вихідний" : fieldsObj?.schedule?.saturday,
                sunday: fieldsObj?.schedule?.sunday?.length < 11 ? "Вихідний" : fieldsObj?.schedule?.sunday
            }
        });
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const createOrUpdate = useCallback((obj = {}) => {
        dispatchModal('createEditDevisions', { obj: obj, handleSuccess: (result) => obj?.id ? updateHandler(result) : createHandler(result) })
    }, [dispatchModal, createHandler, updateHandler]);

    useEffect(() => {
        getData({
            page: page,
            limit: PAGE_LIMIT,
            search: search
        });
    }, [getData, search, page]);

    const columns = useMemo(() => [
        {
            key: "nameDevision",
            children: "Назва аналізу"
        },
        {
            key: "lt",
            children: "Широта"
        },
        {
            key: "ln",
            children: "Довгота"
        },
        {
            key: "adress",
            children: "Адреса"
        },
        {
            key: "weekdays",
            children: "Будні дні"
        },
        {
            key: "saturday",
            children: "Субота"
        },
        {
            key: "sunday",
            children: "Неділя"
        },
        {
            key: "mobile",
            children: "Мобільний"
        },
        {
            key: "stac",
            children: "Стаціонарний"
        },
        {
            key: "controls",
            style: {
                width: 145
            },
            children: "Керування"
        }
    ], []);

    const dataRows = useMemo(() => data.map(itm => ({
        key: itm?._id,
        className: 'admin_table_row',
        rowData: [
            {
                children: itm?.nameDevision
            },
            {
                children: itm?.location?.lt
            },
            {
                children: itm?.location?.ln
            },
            {
                children: itm?.location?.adress
            },
            {
                children: itm?.schedule?.weekdays
            },
            {
                children: itm?.schedule?.saturday
            },
            {
                children: itm?.schedule?.sunday
            },
            {
                children: itm?.phone?.mobile
            },
            {
                children: itm?.phone?.stac
            },
            {
                className: 'admin_controll_btn',
                children: <>
                    <button
                        className='admin_rewrite_btn'
                        onClick={() => createOrUpdate({
                            id: itm?._id,
                            ...itm
                        })}
                    >
                        <FaPen />
                    </button>
                    <button
                        className='admin_delete_btn'
                        onClick={() => alertForConfirmRequest(deleteHandler, itm?._id)}
                    >
                        <MdDelete />
                    </button>
                </>
            }
        ]
    })), [data, createOrUpdate, deleteHandler]);

    const emptyText = useMemo(() => {
        return search === '' ? <div className='admin_empty_table'>
            Список місць надання послуг порожній
            <br />
            <button className='admin_create_element' onClick={() => createOrUpdate()}>Створити</button>
        </div> : `За пошуком "${search}" нікого не знайдено`
    }, [search, createOrUpdate]);

    return (
        <section className='admin_administration_wrapper'>
            <div className='admin_page_content'>
                <h2 className='admin_page_title'>
                    Місця надання послуг
                </h2>
                <div className='admin_filters_block'>
                    <input className='admin_search_input' type='text' placeholder='Пошук' onChange={e => setSearch(e.target.value)} />
                    <button className='admin_create_element' onClick={() => createOrUpdate()}>Створити</button>
                </div>

                <div className='admin_table_block'>
                    <Table
                        columns={columns}
                        data={dataRows}
                        loading={loading}
                        emptyText={emptyText}
                        pagination={{
                            count: 0,
                            page: page,
                            onChange: (number) => setPage(number),
                            pageLimit: PAGE_LIMIT
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default AdminDevisions;