import axios from "axios";
import { PATH } from "../data/apiPath";
import { clearEmptyProperty } from "../utils/helpers";

const getDocument = async (id = null) => {
    return await new Promise((resolve, reject) => {
        axios
            .get(`${PATH}/api/documents/${id}`)
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

const getDocuments = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let {
            limit = 20,
            page = 1,
            filters = {},
            search = "",
        } = rules && rules !== null ? rules : {};
        params.append("limit", limit);
        params.append("page", page);
        if (search && search !== "") params.append("search", search);
        const clearFilters = clearEmptyProperty(filters);
        if (Object.keys(clearFilters).length > 0)
            params.append("filters", JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/documents`, {
                params,
            })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

const getDocumentsCount = async (rules) => {
    return await new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        let { filters = {}, search = "" } =
            rules && rules !== null ? rules : {};
        if (search && search !== "") params.append("search", search);
        const clearFilters = clearEmptyProperty(filters);
        if (Object.keys(clearFilters).length > 0)
            params.append("filters", JSON.stringify(clearFilters));
        axios
            .get(`${PATH}/api/documentsCount`, {
                params,
            })
            .then((result) => resolve(result.data))
            .catch((error) => reject(error));
    });
};

export { getDocument, getDocuments, getDocumentsCount };
