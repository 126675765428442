import React from 'react';
import { HiMiniArrowLeftCircle } from "react-icons/hi2";
import './AdminPanel.scss'

const AdminPanel = () => {
    return (
        <section className='main_admin_panel_wrapper'>
            <div className='main_admin_panel_content'>
                <HiMiniArrowLeftCircle/>
                <h2 className='main_admin_panel_txt'>
                    Оберіть категорію
                </h2>
            </div>
        </section>
    );
};

export default AdminPanel;