import { MdOutlineDiversity3, MdPeopleAlt } from "react-icons/md";
import { TbTestPipe } from "react-icons/tb";
import {
    FaBuildingFlag,
    FaUserDoctor,
    FaPeopleGroup,
    FaRegNewspaper,
    FaPeoplePulling,
    FaBookMedical,
} from "react-icons/fa6";
import { FaQuestionCircle, FaPeopleArrows } from "react-icons/fa";
import { RiServiceFill } from "react-icons/ri";
import { IoIosDocument } from "react-icons/io";
import AdminAdministration from "../pages/Admin/Admin-administration/AdminAdministration";
import AdminAnalyses from "../pages/Admin/Admin-analyses/AdminAnalyses";
import AdminDevisions from "../pages/Admin/Admin-devisions/AdminDevisions";
import AdminDoctors from "../pages/Admin/Admin-doctors/AdminDoctors";
import AdminFaqs from "../pages/Admin/Admin-faqs/AdminFaqs";
import AdminInternships from "../pages/Admin/Admin-internships/AdminInternships";
import AdminNews from "../pages/Admin/Admin-news/AdminNews";
import AdminPartners from "../pages/Admin/Admin-partners/AdminPartners";
import AdminPatrons from "../pages/Admin/Admin-patrons/AdminPatrons";
import AdminServices from "../pages/Admin/Admin-services/AdminServices";
import AdminSpecialties from "../pages/Admin/Admin-specialties/AdminSpecialties";
import AdminVacancies from "../pages/Admin/Admin-vacancies/AdminVacancies";
import AdminDocuments from "../pages/Admin/Admin-documents/AdminDocuments";

const adminNavigationData = [
    {
        linkName: "Адміністрація",
        href: "/admin-panel/administration",
        image: <MdOutlineDiversity3 />,
        component: <AdminAdministration />,
    },
    {
        linkName: "Аналізи",
        href: "/admin-panel/analyses",
        image: <TbTestPipe />,
        component: <AdminAnalyses />,
    },
    {
        linkName: "МНП",
        href: "/admin-panel/devisions",
        image: <FaBuildingFlag />,
        component: <AdminDevisions />,
    },
    {
        linkName: "Лікарі",
        href: "/admin-panel/doctors",
        image: <FaUserDoctor />,
        component: <AdminDoctors />,
    },
    {
        linkName: `Питання/Відповідь`,
        href: "/admin-panel/faqs",
        image: <FaQuestionCircle />,
        component: <AdminFaqs />,
    },
    {
        linkName: `Інтернатура`,
        href: "/admin-panel/internships",
        image: <FaPeopleGroup />,
        component: <AdminInternships />,
    },
    {
        linkName: `Новини`,
        href: "/admin-panel/news",
        image: <FaRegNewspaper />,
        component: <AdminNews />,
    },
    {
        linkName: `Партнери`,
        href: "/admin-panel/partners",
        image: <FaPeopleArrows />,
        component: <AdminPartners />,
    },
    {
        linkName: `Меценати`,
        href: "/admin-panel/patrons",
        image: <FaPeoplePulling />,
        component: <AdminPatrons />,
    },
    {
        linkName: `Послуги`,
        href: "/admin-panel/services",
        image: <RiServiceFill />,
        component: <AdminServices />,
    },
    {
        linkName: `Спеціальності`,
        href: "/admin-panel/specialties",
        image: <FaBookMedical />,
        component: <AdminSpecialties />,
    },
    {
        linkName: `Вакансії`,
        href: "/admin-panel/vacancies",
        image: <MdPeopleAlt />,
        component: <AdminVacancies />,
    },
    {
        linkName: `Документи`,
        href: "/admin-panel/documents",
        image: <IoIosDocument />,
        component: <AdminDocuments />,
    },
];

export default adminNavigationData;
