
import * as yup from "yup";
import { declension } from "../../../../utils/helpers";

const REQUIRED_MESSAGE = 'Поле обовʼязкове для заповнення';
const MIN_MESSAGE = (number = 1) => `Мінімум ${number} ${declension(number, 'символ', 'символа', 'символів')}`;
const PHONE_MESSAGE = 'Заповніть номер коректно';

const schema = yup.object().shape({
    firstName: yup.string().trim().required(REQUIRED_MESSAGE).min(2, MIN_MESSAGE(2)),
    secondName: yup.string().trim().required(REQUIRED_MESSAGE).min(2, MIN_MESSAGE(2)),
    surname: yup.string().trim().required(REQUIRED_MESSAGE).min(2, MIN_MESSAGE(2)),
    position: yup.string().trim().required(REQUIRED_MESSAGE).min(2, MIN_MESSAGE(2)),
    phone: yup.string().trim().required(REQUIRED_MESSAGE).matches(/^\+38\(\d{3}\)-\d{3}-\d{2}-\d{2}$/, PHONE_MESSAGE)
});

export default schema;