import React, { useCallback, useEffect, useState } from 'react';
import './Devisions.scss'
import HeadBlock from '../../components/HeadBlock/HeadBlock';
import { apiDevisions } from '../../axios';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import DevisionMap from '../../components/DevisionMap/DevisionMap';

const Devisions = () => {

    const [devisions, setDevisions] = useState([])

    const getDataDevisions = useCallback(async (params) => {
        try {
            const result = await apiDevisions.getDevisions(params);
            setDevisions(result);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getDataDevisions()
    }, [getDataDevisions])

    return (
        <section className='devisions_wrapper'>
            <div className='devisions_content'>

                {/* Head block */}
                <HeadBlock text={"Місця надання послуг"} />

                <section className='devisions_list'>
                    {devisions?.length !== 0 ? <>
                        {devisions.map(devision => {
                            return <div className='devision_box' key={devision?._id}>
                                <h2 className='devision_box_tit'>{devision?.nameDevision}</h2>
                                <div className="devision_box_content">
                                    <div className='devision_box_info'>
                                        <p className='devision_box_adress'>Адреса : <span>{devision?.location?.adress}</span></p>
                                        <p className='devision_box_schedule'>Графік роботи</p>
                                        <p className='devision_box_schedule_weekdays'>ПН-ПТ : <span>{devision?.schedule?.weekdays}</span></p>
                                        <p className='devision_box_schedule_saturday'>СБ : <span>{devision?.schedule?.saturday}</span></p>
                                        <p className='devision_box_schedule_sundey'>НД : <span>{devision?.schedule?.sunday}</span></p>
                                        <p className='devision_box_schedule_contacts'>Контакти</p>
                                        <a className='devision_contact_mobile' href={`tel:${devision?.phone?.mobile}`}>Мобільний: <span>{devision?.phone?.mobile}</span></a>
                                        <a className='devision_contact_stac' href={`tel:${devision?.phone?.mobile}`}>Стаціонарний: <span>{devision?.phone?.stac}</span></a>
                                        <Button text='Перегляд лікарів' path={`devision/${devision?._id}`} />
                                    </div>
                                    <div className='devision_box_map'>
                                        <DevisionMap lat={devision?.location?.lt} lng={devision?.location?.ln} />
                                    </div>
                                </div>
                            </div>
                        })}
                    </> : <Loader />}
                </section>

            </div>
        </section>
    );
};

export default Devisions;