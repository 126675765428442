import WantToFind from '../pages/Want-to-find/WantToFind';
import Help from '../pages/Help/Help';
import Treatment from '../pages/Treatment/Treatment';
import Devision from '../pages/Devision/Devision';
import DoctorsAll from '../pages/Doctors-all/DoctorsAll';
import Doctor from '../pages/Doctor/Doctor';
import Devisions from '../pages/Devisions/Devisions';
import Services from '../pages/Services/Services';
import News from '../pages/News/News';
import NewInfo from '../pages/NewInfo/NewInfo';
import AboutCliniс from '../pages/About-cliniс/AboutCliniс';

const mainNavigationData = [
    {
        href: '/want-to-find',
        component: <WantToFind/>
    },
    {
        href: '/help',
        component: <Help/>
    },
    {
        href: '/treatment',
        component: <Treatment/>
    },
    {
        href: '/devision',
        component: <Devisions/>
    },
    {
        href: '/devision/:id',
        component: <Devision/>
    },
    {
        href: '/doctors',
        component: <DoctorsAll/>
    },
    {
        href: '/doctors/:id',
        component: <Doctor/>
    },
    {
        href: '/services',
        component: <Services/>
    },
    {
        href: '/news',
        component: <News/>
    },
    {
        href: '/news/:id',
        component: <NewInfo/>
    },
    {
        href: '/about-cliniс',
        component: <AboutCliniс/>
    }
]

export default mainNavigationData